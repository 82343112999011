import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import App from './App';
import Categoria from './views/Categoria';
import DashBoard from './views/DashBoard';
import HistorialVenta from './views/HistorialVenta';
import Inicio from './views/Inicio';
import NotFound from './views/NotFound';
import Producto from './views/Producto';
import ReporteVenta from './views/ReporteVenta';
import Usuario from './views/Usuario';
import Venta from './views/Venta';
import Login from './views/Login';
import Clientes from './views/Clientes';
import Planes from './views/Planes';
import ClienteRecurrente from './views/ClienteRecurrente';
import ConsultaExpress from './views/ConsultaExpress';
import ClienteBaja from './views/ClienteBaja';
import ClientePrepago from './views/ClientePrepago';
import ReporteModificaciones from './views/ReporteModificaciones';
import ReporteVentaNueva from './views/ReporteVentaNueva';
import ClienteVentaNueva from './views/ClienteVentaNueva';
import VentasNuevas from './views/VentasNuevas';
import MantenedorHeader from './views/MantenedorHeader';
import ResporteCierreMes from './views/ReporteCierreMes';
import ReporteBajas from './views/ReporteBajas';
import ReportePrepagos from './views/ReportePrepagos';
import ReporteMontoPlan from './views/ReporteMontoPlan';
import FormVentas from './views/FormVentas';
import UserProvider from "./context/UserProvider"
import VerificarUsuario from './componentes/VerificarUsuario';
import Vendedor from './views/Vendedor';


const root = ReactDOM.createRoot(document.getElementById('wrapper'));

root.render(
    <BrowserRouter>
        <UserProvider>
            <Routes>

                {/*ACONTINUACION ESTABLECEMOS LAS RUTAS DE NUESTRO SISTEMA*/}

                {/*ruta individual sin usar una como base*/}
                <Route index path='/Login' element={<Login />} />

                {/*Permite anidar rutas en base a una*/}
                <Route path='/' element={<App />}>

                    <Route index element={<Inicio />} />
                    <Route path='dashboard' element={<VerificarUsuario> <DashBoard /> </VerificarUsuario>} />
                    <Route path='usuario' element={<VerificarUsuario> <Usuario /> </VerificarUsuario>} />
                    <Route path='producto' element={<VerificarUsuario> <Producto /> </VerificarUsuario>} />
                    <Route path='categoria' element={<VerificarUsuario> <Categoria /> </VerificarUsuario>} />
                    <Route path='venta' element={<VerificarUsuario> <Venta /> </VerificarUsuario>} />
                    <Route path='historialventa' element={<VerificarUsuario> <HistorialVenta /> </VerificarUsuario>} />
                    <Route path='reporteventa' element={<VerificarUsuario > <ReporteVenta /> </VerificarUsuario>} />
                    <Route path='clientes' element={<VerificarUsuario > <Clientes /> </VerificarUsuario>} />
                    <Route path='planes' element={<VerificarUsuario > <Planes /> </VerificarUsuario>} />
                    <Route path='clienteRecurrente' element={<VerificarUsuario > <ClienteRecurrente /> </VerificarUsuario>} />
                    <Route path='consultaExpress' element={<VerificarUsuario > <ConsultaExpress /> </VerificarUsuario>} />
                    <Route path='clienteBaja' element={<VerificarUsuario > <ClienteBaja /> </VerificarUsuario>} />
                    <Route path='clientePrepago' element={<VerificarUsuario > <ClientePrepago /> </VerificarUsuario>} />
                    <Route path='clienteVentaNueva' element={<VerificarUsuario > <ClienteVentaNueva /> </VerificarUsuario>} />
                    <Route path='reporteModificaciones' element={<VerificarUsuario > <ReporteModificaciones /> </VerificarUsuario>} />
                    <Route path='reporteVentaNueva' element={<VerificarUsuario > <ReporteVentaNueva /> </VerificarUsuario>} />
                    <Route path='mantenedorHeader' element={<VerificarUsuario > <MantenedorHeader /> </VerificarUsuario>} />
                    <Route path='ventasNuevas' element={<VerificarUsuario > <VentasNuevas /> </VerificarUsuario>} />
                    <Route path='reporteCierreMes' element={<VerificarUsuario > <ResporteCierreMes /> </VerificarUsuario>} />
                    <Route path='reporteBajas' element={<VerificarUsuario > <ReporteBajas /> </VerificarUsuario>} />
                    <Route path='reportePrepagos' element={<VerificarUsuario > <ReportePrepagos /> </VerificarUsuario>} />
                    <Route path='reporteMontoPlan' element={<VerificarUsuario > <ReporteMontoPlan /> </VerificarUsuario>} />
                    <Route path='formVentas' element={<VerificarUsuario > <FormVentas /> </VerificarUsuario>} />
                    <Route path='vendedor' element={<VerificarUsuario > <Vendedor /> </VerificarUsuario>} />
                </Route>
                <Route path='*' element={<NotFound />} />
                
            </Routes>

        </UserProvider>
       

    </BrowserRouter>
);

