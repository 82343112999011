import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloContrato = {
    idCliente: 0,
    rut: "",
    nombres: "",
    apellidoP: "",
    apellidoM: "",
    telefono: "",
    estadoContrato: "",
    observaciones: "",
    idPlan: 0,
    plan: "",
    montoPlan: 0,
    rutPagador: "",
    fechaIngreso: "",
    diaCobro: 0,
    formaPago: "",
    montoDeuda: 0,
    mesesDeuda: 0,
    categoriaDeuda: "",
    idEstadoContrato: 0,
    idContrato: 0,
    idTipoBaja: 0
}

const modeloRetencion = {
    idRetencion: 0,
    idContrato: 0,
    monto: 0,
    fechaInicio: "",
    cantidadMeses: 0,
    usuarioCreacion: "",
    fechaCreacion: "",
    observaciones: "",
    activo: false
}

const modeloPlan = {
    idPlan: 0,
    montoPlan: 0,
    idEstadoContrato: 0,
    observaciones: "",
    saldo: 0,
    idTipoBaja: 0
}

const modeloEstado = {
    idEstadoContrato: 0,
    descripcion: ""
}



const ClienteRecurrente = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    useEffect(() => {
        obtenerPlanes();
        obtenerEstados();
        obtenerTipoBajas();
    }, [])

    const [contrato, setContrato] = useState(modeloContrato);
    const [plan, setPlan] = useState(modeloPlan);
    const [estado, setEstado] = useState(modeloEstado);
    const [retencion, setRetencion] = useState(modeloRetencion);
    const [busqueda, setBusqueda] = useState("")
    const [clientes, setClientes] = useState([])
    const [verModal, setVerModal] = useState(false)
    const [verModalUpdate, setVerModalUpdate] = useState(false)
    const [verModalHistorial, setVerModalHistorial] = useState(false)
    const [verModalRetencion, setVerModalRetencion] = useState(false)
    const [detalleCliente, setDetalleCliente] = useState({});
    const [planes, setPlanes] = useState([]);
    const [estadosContrato, setEstadosContrato] = useState([]);
    const [historial, setHistorial] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [pagos, setPagos] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [newRet, setNewRet] = useState(modeloRetencion);
    const [verModalCreacionRet, setVerModalCreacionRet] = useState(false);
    const [pendienteBusqueda, setPendienteBusqueda] = useState(false);
    const [verModalCambioSaldo, setVerModalCambioSaldo] = useState(false);
    const [verModalUpdateEstado, setVerModalUpdateEstado] = useState(false);
    const [verModalAbierto, setVerModalAbierto] = useState("");

    const [tipoBajas, setTipoBajas] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);



    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);


    const seleccionoBaja = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        value = e.target.value
        setPlan({
            ...plan,
            [e.target.name]: value
        })

        if (value == 3) {
            setIsDisabled(false)
        }
        else {
            setIsDisabled(true)
        }

    }

    const cambiaBaja = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        //value = e.target.value
        //setContrato({
        //    ...contrato,
        //    [e.target.name]: value
        //}) 
        value = e.target.value
        
        setPlan({
            ...plan,
            [e.target.name]: value
        })
    }


    const handleChange = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        value = e.target.value
        setContrato({
            ...contrato,
            [e.target.name]: value
        })

        value = e.target.value
        setPlan({
            ...plan,
            [e.target.name]: value
        })
    }

    const alCambiar = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        value = e.target.value
        setNewRet({
            ...newRet,
            [e.target.name]: value
        })
    }

    const obtenerTipoBajas = async () => {
        let response = await fetch("api/mantenedor/ListaTipoBaja");
        if (response.ok) {
            let data = await response.json()
            setTipoBajas(data)
        }
    }

    const obtenerPlanes = async () => {
        let response = await fetch("api/mantenedor/ListaPlanes");
        if (response.ok) {
            let data = await response.json()
            setPlanes(data)
        }
    }

    const obtenerEstados = async () => {
        let response = await fetch("api/mantenedor/ListaEstadosContratos");
        if (response.ok) {
            let data = await response.json()
            setEstadosContrato(data)
        }
    }

    const buscarHistorial = () => {
        //const api = fetch("api/mantenedor/ListaPlanes/" + modeloContrato.idContrato)
        const api = fetch("api/mantenedor/BuscarHistorial/" + contrato.idContrato)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setHistorial(data);
            }).catch((error) => {
                setHistorial([]);
            })

    }

    const buscarPagos = () => {
        //const api = fetch("api/mantenedor/ListaPlanes/" + modeloContrato.idContrato)
        const api = fetch("api/mantenedor/BuscarPagos/" + detalleCliente.idContrato)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPagos([]);
                setPagos(data);
                //setPendiente(true);
            }).catch((error) => {
                setPagos([]);
            })

    }


    const generarRetencion = () => {

        let retencion = {
            idContrato: contrato.idContrato,
            monto: newRet.monto,
            fechaInicio: fechaInicio,
            cantidadMeses: newRet.cantidadMeses,
            usuarioCreacion: dataUser.nombre,
            observaciones: newRet.observaciones
        }


        if (newRet.monto == undefined || newRet.monto == "" || newRet.cantidadMeses == undefined || newRet.cantidadMeses == "" || newRet.observaciones == undefined || newRet.observaciones == "") {
            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {

            const api = fetch("api/mantenedor/GenerarRetencion", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(retencion)
            })
                .then((response) => {
                    return response.ok;
                })
                .then((dataJson) => {

                    buscarClientes();
                    setNewRet([]);
                    //cerrarModalCreaRet();
                    setVerModalCreacionRet(!verModalCreacionRet);
                    Swal.fire(
                        'Actualización exitosa!',
                        //'Numero de venta : ' + data.numeroDocumento,
                        'success'
                    )

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No se pudo realizar la modificación',
                        'error'
                    )
                })
        }

    }

    const actualizarContrato = () => {


        let Contrato = {
            idTipo: 2,
            idContrato: contrato.idContrato,
            idPlan: plan.idPlan,
            montoPlan: plan.montoPlan,
            //idEstadoContrato: plan.idEstadoContrato,
            observaciones: plan.observaciones,
            idUsuario: JSON.parse(user).idUsuario
        }

        if (plan.idPlan == undefined || plan.idPlan == 0 || plan.montoPlan == undefined || plan.montoPlan == 0 || plan.observaciones == undefined || plan.observaciones == "") {
            //|| plan.idEstadoContrato == undefined || plan.idEstadoContrato == 0 
            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {
            const api = fetch("api/mantenedor/ActualizarContrato", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Contrato)
            })
                .then((response) => {
                    return response.ok;
                })
                .then((dataJson) => {
                    //reestablecer();
                    //var data = dataJson;
                    buscarClientes();
                    setPlan([]);
                    setVerModalUpdate(!verModalUpdate);
                    Swal.fire(
                        'Actualización exitosa!',
                        //'Numero de venta : ' + data.numeroDocumento,
                        //'success'
                    )

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No se pudo realizar la modificación',
                        'error'
                    )
                    //console.log("No se pudo enviar la venta ", error)
                })
        }
    }

    const actualizarEstado = () => {


        let Contrato = {
            idTipo: 3,
            idContrato: contrato.idContrato,
            idEstadoContrato: plan.idEstadoContrato,
            observaciones: plan.observaciones,
            idUsuario: JSON.parse(user).idUsuario,
            idTipoBaja: plan.idTipoBaja
        }

        if (plan.idEstadoContrato == undefined || plan.idEstadoContrato == 0) {

            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {

            if (plan.idEstadoContrato == 3 && plan.idTipoBaja == 0) {
                Swal.fire(
                    'Opps!',
                    'Es necesario seleccionar el motivo de baja.',
                    'error'
                )
            }
            else {

                const api = fetch("api/mantenedor/ActualizarEstado", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(Contrato)
                })
                    .then((response) => {
                        return response.ok;
                    })
                    .then((dataJson) => {
                        //reestablecer();
                        //var data = dataJson;
                        buscarClientes();
                        setPlan([]);
                        setVerModalUpdateEstado(!verModalUpdateEstado);
                        Swal.fire(
                            'Actualización exitosa!',
                            //'Numero de venta : ' + data.numeroDocumento,
                            //'success'
                        )

                    }).catch((error) => {
                        Swal.fire(
                            'Opps!',
                            'No se pudo realizar la modificación',
                            'error'
                        )
                        //console.log("No se pudo enviar la venta ", error)
                    })
            }
        }

    }

    const actualizarSaldo = () => {


        let Contrato = {
            idTipo: 5,
            idContrato: contrato.idContrato,
            idEstadoContrato: plan.idEstadoContrato,
            observaciones: plan.observaciones,
            idUsuario: JSON.parse(user).idUsuario,
            saldo: plan.saldo
        }

        if (plan.observaciones == undefined || plan.observaciones == 0 || plan.saldo == undefined || plan.saldo == '') {

            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {

            const api = fetch("api/mantenedor/ActualizarEstado", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Contrato)
            })
                .then((response) => {
                    //return response.ok;
                    if (response.ok) {
                        buscarClientes();
                        setPlan([]);
                        setVerModalCambioSaldo(!verModalCambioSaldo);
                        Swal.fire(
                            'Actualización exitosa!',
                            //'Numero de venta : ' + data.numeroDocumento,
                            //'success'
                        )
                    } else {
                        Swal.fire(
                            'Opps!',
                            'No se pudo realizar la modificación',
                            'error'
                        )
                    }
                })
        }

    }


    const buscarClientes = () => {
        setPendienteBusqueda(true)
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        const api = fetch("api/contrato/ListarClientesRecurrente/" + busqueda)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setClientes(data);
                setPagos(data.listaPagos);
                //setRetencion(data.listaRetenciones);
                setPendienteBusqueda(false)

            }).catch((error) => {
                setPendienteBusqueda(false)
                setClientes([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })

    }

    const columnsRetencion = [
        {
            name: <div>Fecha Creación</div>,
            selector: row => row.fechaCreacion,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Monto</div>,
            selector: row => row.monto,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Fecha Inicio</div>,
            selector: row => row.fechaInicio,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Meses</div>,
            selector: row => row.cantidadMeses,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Usuario Creación</div>,
            selector: row => row.usuarioCreacion,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Activo</div>,
            selector: row => row.activo,
            sortable: true,
            wrap: true,
            cell: row => {
                let clase;
                clase = row.activo ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.activo ? "Activa" : "No Activa"}</span>
                )
            }

        },
    ]

    const columns2 = [
        {
            name: <div>Saldo Pend.</div>,
            selector: row => row.saldoPendiente,
            sortable: true,
            wrap: true

        },
        {
            name: <div>A Recaudar</div>,
            selector: row => row.montoARecaudar,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Fecha Pago</div>,
            selector: row => row.fechaPago,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Forma Pago</div>,
            selector: row => row.formaPago,
            sortable: true,
            wrap: true
        },
        {
            name: <div>N° Transacción</div>,
            selector: row => row.numeroTransaccion,
            sortable: true,
            wrap: true
        },
        {
            name: 'Pago',
            selector: row => row.montoPago,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Clasif. Pago</div>,
            selector: row => row.clasificacionMontoPago,
            sortable: true,
            wrap: true
        },
        {
            name: 'Saldo',
            selector: row => row.saldo,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Clasif. Saldo</div>,
            selector: row => row.clasificacionSaldo,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Origen',
            selector: row => row.origen,
            sortable: true,
            wrap: true
        },
        {
            name: 'Recaudación',
            selector: row => row.estadoRecaudacion,
            sortable: true,
            wrap: true
        },
        {
            name: 'Sub. Reca',
            selector: row => row.subEstadoRecaudacion,
            sortable: true,
            wrap: true,
            grow: 2
        },
        {
            name: 'Comentarios',
            selector: row => row.comentarios,
            sortable: true,
            wrap: true
        },

    ];

    const columns = [
        {
            name: <div>Tipo Cambio</div>,
            selector: row => row.tipoLog,
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 2
        },
        {
            name: 'Nomple Plan',
            selector: row => row.nombrePlan,
            sortable: true,
            grow: 2

        },
        {
            name: 'Monto Plan',
            selector: row => row.montoPlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Estado Contrato</div>,
            selector: row => row.estadoContrato,
            sortable: true
        },
        {
            name: <div>Tipo Deuda</div>,
            selector: row => row.tipoDeuda,
            sortable: true
        },
        {
            name: <div>Estado Final</div>,
            selector: row => row.estadoFinal,
            sortable: true
        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true,
            grow: 2,
            wrap: true

        },  
        {
            name: 'Usuario',
            selector: row => row.usuario,
            sortable: true
        },

    ];

    const columnsBusqueda = [
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Cliente</div>,
            selector: row => row.nombres + ' ' + row.apellidoP,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Teléfono</div>,
            selector: row => row.telefono,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Estado</div>,
            selector: row => row.estadoContrato,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.plan,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => mostrarModal(row)}>
                        <i className="fa fa-eye" aria-hidden="true"></i> Ver detalle
                    </Button>

                </>
            ),
        },
        {
            name: '',
            cell: row => (
                <>
                    <Row>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="primary" size="sm" className="mr-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Modificación Plan"
                                    onClick={() => mostrarModalUpdate(row)}
                                >
                                    <i className="fas fa-pen-alt"></i>
                                </Button>
                            ) : (<Button disabled color="primary" size="sm" className="mr-2"

                            >
                                <i className="fas fa-pen-alt"></i>
                            </Button>)}
                        </Col>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="danger" size="sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Retención"
                                    onClick={() => mostrarModalRetencion(row)}

                                >
                                    <i className="fas fa-percentage"></i>
                                </Button>
                            ) : (<Button disabled color="danger" size="sm"

                            >
                                <i className="fas fa-percentage"></i>
                            </Button>)}
                        </Col>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="warning" size="sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Modificación Estado"
                                    onClick={() => mostrarModalUpdateEstado(row)}

                                >
                                    <i className="fas fa-tags"></i>
                                </Button>
                            ) : (<Button disabled color="warning" size="sm"

                            >
                                <i className="fas fa-tags"></i>
                            </Button>)}
                        </Col>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="info" size="sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Modificación Saldo"
                                    onClick={() => mostrarModalCambioSaldo(row)}

                                >
                                    <i class="fas fa-dollar-sign"></i>
                                </Button>
                            ) : (<Button disabled color="info" size="sm"

                            >
                                <i class="fas fa-dollar-sign"></i>
                            </Button>)}
                        </Col>
                    </Row>
                </>
            ),

        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };



    const mostrarModalUpdate = (data) => {
        //setDetalleCliente(data);
        setContrato(data);
        setVerModalUpdate(!verModalUpdate);
    }

    const mostrarModalUpdateEstado = (data) => {
        //setDetalleCliente(data);
        setContrato(data);
        setVerModalUpdateEstado(!verModalUpdateEstado);
    }

    const mostrarModalCambioSaldo = (data) => {
        //setDetalleCliente(data);
        setContrato(data);
        setVerModalCambioSaldo(!verModalCambioSaldo);
        setPlan([])
    }

    const mostrarModalRetencion = (data) => {
        setContrato(data);
        setRetencion(data.listaRetenciones);
        setVerModalRetencion(!verModalRetencion);
    }


    const mostrarModal = (data) => {
        setDetalleCliente(data)
        setVerModal(!verModal);
        //buscarPagos();

    }

    const mostrarModalHistorial = (desde) => {

        if (desde == 1) {
            setVerModalUpdate(!verModalUpdate)
            setVerModalAbierto("plan")
        }
        else if (desde == 2) {
            setVerModalUpdateEstado(!verModalUpdateEstado)
            setVerModalAbierto("estado")
        }
        else {
            setVerModalCambioSaldo(!verModalCambioSaldo)
            setVerModalAbierto("saldo")
        }
        buscarHistorial();
        setPendiente(false)

        setVerModalHistorial(!verModalHistorial);
    }

    const cerrarModalHistorial = () => {

        if (verModalAbierto == "plan") {
            setVerModalUpdate(!verModalUpdate)
        }
        else if (verModalAbierto == "estado") {
            setVerModalUpdateEstado(!verModalUpdateEstado)
        }
        else {
            setVerModalCambioSaldo(!verModalCambioSaldo)

        }
        setVerModalHistorial(!verModalHistorial);
    }

    const mostrarModalCreaRet = () => {
        setVerModalRetencion(!verModalRetencion)
        setVerModalCreacionRet(!verModalCreacionRet);
    }

    const cerrarModalCreaRet = () => {
        setVerModalRetencion(!verModalRetencion)
        setVerModalCreacionRet(!verModalCreacionRet);
    }


    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Busqueda Clientes Recurrente
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Cliente:</Label>
                                        <Input bsSize="sm" value={busqueda} onChange={(e) => setBusqueda(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscarClientes}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col sm={12}>
                                    <div className="table table-responsive">
                                        <DataTable
                                            columns={columnsBusqueda}
                                            data={clientes}
                                            progressPending={pendienteBusqueda}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                            paginationPerPage={10}
                                            noDataComponent="No se encontraron resultados"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {/*<Row>*/}
                            {/*    <Col sm="12">*/}
                            {/*        <Table striped responsive size="sm">*/}
                            {/*            <thead>*/}
                            {/*                <tr>*/}
                            {/*                    <th>Rut</th>*/}
                            {/*                    <th>Nombre</th>*/}
                            {/*                    <th>Apellido Paterno</th>*/}
                            {/*                    <th>Apellido Materno</th>*/}
                            {/*                    <th>Teléfono</th>*/}
                            {/*                    <th>Estado</th>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th></th>*/}
                            {/*                    <th></th>*/}
                            {/*                </tr>*/}
                            {/*            </thead>*/}
                            {/*            <tbody>*/}
                            {/*                {*/}
                            {/*                    (clientes.length < 1) ? (*/}
                            {/*                        <tr>*/}
                            {/*                            <td colSpan="7" style={{ textAlign: "center", fontSize: "5px !important", color: "red" }}>*/}
                            {/*                                Sin resultados*/}
                            {/*                            </td>*/}
                            {/*                        </tr>*/}
                            {/*                    ) : (*/}

                            {/*                        clientes.map((item) => (*/}
                            {/*                            <tr style={{ textAlign: "left", fontSize: "12px" }} key={item.idCliente}>*/}
                            {/*                                <td>{item.rut}</td>*/}
                            {/*                                <td>{item.nombres}</td>*/}
                            {/*                                <td>{item.apellidoP}</td>*/}
                            {/*                                <td>{item.apellidoM}</td>*/}
                            {/*                                <td>{item.telefono}</td>*/}
                            {/*                                <td>{item.estadoContrato}</td>*/}
                            {/*                                <td>*/}
                            {/*                                    <Button size="sm" color="info" outline*/}
                            {/*                                        onClick={() => mostrarModal(item)}*/}
                            {/*                                    >*/}
                            {/*                                        <i className="fa fa-eye" aria-hidden="true"></i> Ver detalle*/}
                            {/*                                    </Button>*/}
                            {/*                                </td>*/}

                            {/*                                <td>*/}
                            {/*                                    {(administrador) ? (*/}
                            {/*                                        <Button color="primary" size="sm" className="mr-2"*/}
                            {/*                                            onClick={() => mostrarModalUpdate(item)}*/}
                            {/*                                        >*/}
                            {/*                                            <i className="fas fa-pen-alt"></i>*/}
                            {/*                                        </Button>*/}
                            {/*                                    ) : (<Button disabled color="primary" size="sm" className="mr-2"*/}

                            {/*                                    >*/}
                            {/*                                        <i className="fas fa-pen-alt"></i>*/}
                            {/*                                    </Button>)}*/}
                            {/*                                </td>*/}
                            {/*                                <td>*/}
                            {/*                                    {(administrador) ? (*/}
                            {/*                                        <Button color="danger" size="sm"*/}
                            {/*                                            onClick={() => mostrarModalRetencion(item)}*/}

                            {/*                                        >*/}
                            {/*                                            <i className="fas fa-percentage"></i>*/}
                            {/*                                        </Button>*/}
                            {/*                                    ) : (<Button disabled color="danger" size="sm"*/}

                            {/*                                    >*/}
                            {/*                                        <i className="fas fa-percentage"></i>*/}
                            {/*                                    </Button>)}*/}
                            {/*                                </td>*/}
                            {/*                            </tr>*/}
                            {/*                        ))*/}
                            {/*                    )*/}
                            {/*                }*/}
                            {/*            </tbody>*/}
                            {/*        </Table>*/}

                            {/*    </Col>*/}

                            {/*</Row>*/}

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="xl" isOpen={verModal} className="modal-dialog-scrollable">
                <ModalHeader>
                    Detalle Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                            <div className="table table-responsive table-striped">
                                <DataTable
                                    columns={columns2}
                                    data={detalleCliente.listaPagos}
                                    //progressPending={pendiente}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    customStyles={customStyles}
                                    paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                    paginationPerPage={5}
                                    highlightOnHover
                                    noDataComponent="No se encontraron resultados"
                                />
                            </div>
                        </Col>
                        {/*<Col sm={12}>*/}
                        {/*    <Table className="table table-striped" size="sm" style={{ fontSize: "11px" }}>*/}
                        {/*        <thead>*/}
                        {/*            <tr>*/}
                        {/*                <th>Fecha Pago</th>*/}
                        {/*                <th>Forma Pago</th>*/}
                        {/*                <th>N° Transacción</th>*/}
                        {/*                <th>Pago</th>*/}
                        {/*                <th>Clas. Pago</th>*/}
                        {/*                <th>Clas. Descuento</th>*/}
                        {/*                <th>Saldo</th>*/}
                        {/*                <th>Clasf.Saldo</th>*/}
                        {/*                <th>Origen</th>*/}
                        {/*                <th>Gestión Cobro</th>*/}
                        {/*                <th>Sub. Gestión Cobro</th>*/}
                        {/*                <th>Recaudación</th>*/}
                        {/*                <th>Sub. Recaudación</th>*/}
                        {/*                <th>Coment.</th>*/}
                        {/*            </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*            {*/}
                        {/*                (detalleCliente.listaContactos == undefined || dataUser.idRolNavigation.descripcion == "Empleado") ? (*/}
                        {/*                    <tr><td colSpan={4}>Sin Contactos</td></tr>*/}
                        {/*                ) : (*/}
                        {/*                    detalleCliente.listaPagos.map((item) => (*/}
                        {/*                        <tr key={item.idPago}>*/}
                        {/*                            <td>{item.fechaPago}</td>*/}
                        {/*                            <td>{item.formaPago}</td>*/}
                        {/*                            <td>{item.numeroTransaccion}</td>*/}
                        {/*                            <td>{item.montoPago}</td>*/}
                        {/*                            <td>{item.clasificacionMontoPago}</td>*/}
                        {/*                            <td>{item.clasificacionDescuento}</td>*/}
                        {/*                            <td>{item.saldo}</td>*/}
                        {/*                            <td>{item.clasificacionSaldo}</td>*/}
                        {/*                            <td>{item.origen}</td>*/}
                        {/*                            <td>{item.estadoGestionCobro}</td>*/}
                        {/*                            <td>{item.subEstadoGestionCobro}</td>*/}
                        {/*                            <td>{item.estadoRecaudacion}</td>*/}
                        {/*                            <td>{item.subEstadoRecaudacion}</td>*/}
                        {/*                            <td>{item.comentarios}</td>*/}
                        {/*                        </tr>*/}
                        {/*                    ))*/}
                        {/*                )*/}
                        {/*            }*/}

                        {/*        </tbody>*/}
                        {/*    </Table>*/}
                        {/*</Col>*/}
                    </Row>
                    <br></br>
                    <Row >
                        <Col sm={12}>
                            <Table className="table table-striped" size="sm" style={{ fontSize: "11px" }}>
                                <thead>
                                    <tr>
                                        <th>Fecha Contacto</th>
                                        <th>Tipo Contacto</th>
                                        <th>Contactado</th>
                                        <th>Pagado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (detalleCliente.listaContactos == undefined) ? (
                                            <tr><td colSpan={4}>Sin Contactos</td></tr>
                                        ) : (
                                            detalleCliente.listaContactos.map((item) => (
                                                <tr key={item.idContacto}>
                                                    <td>{item.fechaContacto}</td>
                                                    <td>{item.tipoContacto}</td>
                                                    <td>{(item.contactado == 1) ? ('SI') : ('NO')}</td>
                                                    <td>{(item.pagoDeuda == 1) ? ('SI') : ('NO')}</td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModal(!verModal)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalUpdate} className="modal-dialog-scrollable">
                <ModalHeader>
                    Módificación Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nuevo Plan</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idPlan" onChange={handleChange} defaultValue={0}>
                                    <option value={0}>Seleccionar</option>
                                    {
                                        planes.map((item) => {
                                            if (item.activo)
                                                return (<option key={item.idPlan} value={item.idPlan}>{item.nombrePlan}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Mensual</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" name="montoPlan" onChange={handleChange} defaultValue={0} type="number" />
                            </FormGroup>
                        </Col>
                        {/*<Col sm={3}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado</Label>*/}
                        {/*        <Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idEstadoContrato" onChange={handleChange} defaultValue={0}>*/}
                        {/*            <option value={0}>Seleccionar</option>*/}
                        {/*            {*/}
                        {/*                estadosContrato.map((item) => {*/}
                        {/*                    return (<option key={item.idEstadoContrato} value={item.idEstadoContrato}>{item.descripcion}</option>)*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Input>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Observaciones :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="lg" name="observaciones" onChange={handleChange} defaultValue={contrato.observaciones} type="textarea" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={3}>
                            <Button size="sm" color="primary" block onClick={actualizarContrato} >Guardar</Button>
                        </Col>
                        <Col sm={2}>
                            <Button size="sm" color="info" block onClick={() => mostrarModalHistorial(1)} >Historial</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalUpdate(!verModalUpdate)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalUpdateEstado} className="modal-dialog-scrollable">
                <ModalHeader>
                    Módificación Estado
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nuevo Estado</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idEstadoContrato" onChange={seleccionoBaja} defaultValue={plan.idEstadoContrato}>
                                    <option value={0}>Seleccionar</option>
                                    {
                                        estadosContrato.map((item) => {
                                            return (<option key={item.idEstadoContrato} value={item.idEstadoContrato}>{item.descripcion}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Motivo Baja</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idTipoBaja" onChange={cambiaBaja} disabled={isDisabled}>
                                    <option value={0}>Seleccionar</option>
                                    {
                                        tipoBajas.map((item) => {
                                            return (<option key={item.idTipoBaja} value={item.idTipoBaja}>{item.tipoBaja}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Observaciones :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="lg" name="observaciones" onChange={handleChange} defaultValue={plan.observaciones} type="textarea" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={3}>
                            <Button size="sm" color="primary" block onClick={actualizarEstado} >Guardar</Button>
                        </Col>
                        <Col sm={2}>
                            <Button size="sm" color="info" block onClick={() => mostrarModalHistorial(2)} >Historial</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalUpdateEstado(!verModalUpdateEstado)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalRetencion} className="modal-dialog-scrollable">
                <ModalHeader>
                    Retenciones Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>

                    {(retencion.length > 0) ? (
                        <Row>
                            <Col sm={12}>
                                <div className="table table-responsive table-striped">
                                    <DataTable
                                        columns={columnsRetencion}
                                        data={retencion}
                                        //progressPending={pendiente}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        customStyles={customStyles}
                                        paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                        paginationPerPage={5}
                                        highlightOnHover
                                        noDataComponent="No se encontraron resultados"
                                    />
                                </div>
                            </Col>
                        </Row>
                    ) : (<Row>
                        <Row>
                            <Col sm={12}>
                                <Col sm={12}>
                                    Sin retenciones previas.
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                    )
                    }
                    <hr></hr>
                    <Row>
                        <Col sm={12}>
                            <Button size="sm" color="primary" onClick={mostrarModalCreaRet} >Crear Retención</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalRetencion(!verModalRetencion)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalHistorial}>
                <ModalHeader>
                    Historial
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <div className="table table-responsive">
                            <DataTable
                                columns={columns}
                                data={historial}
                                progressPending={pendiente}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                paginationPerPage={5}
                                noDataComponent="No se encontraron resultados"
                            />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalHistorial}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalCambioSaldo} className="modal-dialog-scrollable">
                <ModalHeader>
                    Modificación Saldo
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nuevo Saldo</Label>
                                {/*<Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idEstadoContrato" onChange={handleChange} defaultValue={plan.idEstadoContrato}>*/}
                                {/*    <option value={0}>Seleccionar</option>*/}
                                {/*    {*/}
                                {/*        estadosContrato.map((item) => {*/}
                                {/*            return (<option key={item.idEstadoContrato} value={item.idEstadoContrato}>{item.descripcion}</option>)*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</Input>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="lg" name="saldo" onChange={handleChange} defaultValue={plan.saldo} type="number" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Observaciones :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="lg" name="observaciones" onChange={handleChange} defaultValue={plan.observaciones} type="textarea" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={3}>
                            <Button size="sm" color="primary" block onClick={actualizarSaldo} >Guardar</Button>
                        </Col>
                        <Col sm={2}>
                            <Button size="sm" color="info" block onClick={() => mostrarModalHistorial(3)} >Historial</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalCambioSaldo(!verModalCambioSaldo)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalCreacionRet}>
                <ModalHeader>
                    Retención
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Retención</Label>
                                    <Input style={{ fontSize: "12px" }} bsSize="sm" type="number" name="monto" onChange={alCambiar} defaultValue={newRet.monto}>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses</Label>
                                    <Input style={{ fontSize: "12px" }} bsSize="sm" name="cantidadMeses" onChange={alCambiar} defaultValue={newRet.cantidadMeses} type="number" />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label>Fecha Inicio:</Label>
                                    <DatePicker
                                        className="form-control form-control-sm"
                                        selected={fechaInicio}
                                        onChange={(date) => setFechaInicio(date)}
                                        dateFormat='dd/MM/yyyy'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label style={{ fontSize: "12px" }} className="font-weight-bold">Observaciones :</Label>
                                    <Input style={{ fontSize: "12px" }} bsSize="lg" name="observaciones" onChange={alCambiar} defaultValue={newRet.observaciones} type="textarea" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row >
                            <Col sm={3}>
                                <Button size="sm" color="primary" block onClick={generarRetencion} >Guardar</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalCreaRet}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ClienteRecurrente;