import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'

import { UserContext } from '../context/UserProvider';
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom"

const modeloPlan = {
    idPlan: 0,
    nombrePlan: "",
    activo: true
}

const modeloHeader = {
    idcontrato: 0,
    idCliente: 0,
    rut: "",
    nombres: "",
    apellidoP: "",
    apellidoM: "",
    telefono: "",
    diaCobro: 0,
    rutPagador: "",
    formaPago: "",
    idFormaPago: 0
}

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const MantenedorHeader = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    let administrador = false;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const [plan, setPlan] = useState(modeloPlan);
    const [header, setHeader] = useState(modeloHeader);
    const [headers, setHeaders] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [formasPago, setFormasPago] = useState([]);
    const [pendienteBusqueda, setPendienteBusqueda] = useState(false);
    const [busqueda, setBusqueda] = useState("")

    const handleChange = (e) => {
        //let value = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        let value;

        value = e.target.value
        setHeader({
            ...header,
            [e.target.name]: value
        })
    }

    const buscarClientes = () => {
        setPendienteBusqueda(true)
        const api = fetch("api/mantenedor/ListarHeader/" + busqueda)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setHeaders(data);
                setPendienteBusqueda(false)
                //setRetencion(data.listaRetenciones);

            }).catch((error) => {
                setPendienteBusqueda(false)
                setHeaders([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const obtenerFormasPago = async () => {
        let response = await fetch("api/mantenedor/ListaFormaPago");
        if (response.ok) {
            let data = await response.json()
            setFormasPago(data)
        }
    }

    useEffect(() => {
        //obtenerUsuarios();
        obtenerFormasPago();
    }, [])

    const guardarCambios = () => {

        let HeaderCambios = {
            idContrato: header.idContrato,
            idCliente: header.idCliente,
            rut: header.rut,
            nombres: header.nombres,
            apellidoP: header.apellidoP,
            apellidoM: header.apellidoM,
            telefono: header.telefono,
            diaCobro: header.diaCobro,
            rutPagador: header.rutPagador,
            formaPago: header.formaPago,
            idFormaPago: header.idFormaPago,
            idUsuario: JSON.parse(user).idUsuario
        }

        if (header.rut == undefined || header.rut == "" ||
            header.nombres == undefined || header.nombres == "" ||
            header.apellidoP == undefined || header.apellidoP == "" ||
            header.apellidoM == undefined || header.apellidoM == "" ||
            header.telefono == undefined || header.telefono == "" ||
            header.diaCobro == undefined || header.diaCobro == "" ||
            header.rutPagador == undefined || header.rutPagador == "" ||
            header.formaPago == undefined || header.formaPago == "") {
            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {
            const api = fetch("api/mantenedor/GuardarHeader", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(HeaderCambios)
            })
                .then((response) => {
                    return response.ok;
                })
                .then((dataJson) => {

                    buscarClientes();
                    setVerModal(!verModal);
                    //setNewRet([]);
                    ////cerrarModalCreaRet();
                    //setVerModalCreacionRet(!verModalCreacionRet);
                    Swal.fire(
                        'Actualización exitosa!',
                        //'Numero de venta : ' + data.numeroDocumento,
                        'success'
                    )

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No se pudo realizar la modificación',
                        'error'
                    )
                })
        }
    }

    const columnsBusqueda = [
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Cliente</div>,
            selector: row => row.nombres + ' ' + row.apellidoP,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Teléfono</div>,
            selector: row => row.telefono,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Plan</div>,
            selector: row => row.nombrePlan,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => mostrarModal(row)}>
                        <i className="fa fa-eye" aria-hidden="true"></i> Modificar
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const mostrarModal = (data) => {
        setHeader(data)
        setVerModal(!verModal);
        //buscarPagos();

    }


    //const abrirEditarModal = (data) => {
    //    setPlan(data);
    //    setVerModal(!verModal);
    //}

    //const cerrarModal = () => {
    //    setPlan(modeloPlan)
    //    setVerModal(!verModal);
    //}

    //const guardarCambios = async () => {

    //    let response;
    //    if (plan.idPlan == 0) {
    //        response = await fetch("api/mantenedor/GuardarPlan", {
    //            method: 'POST',
    //            headers: {
    //                'Content-Type': 'application/json;charset=utf-8'
    //            },
    //            body: JSON.stringify(plan)
    //        })

    //    } else {
    //        response = await fetch("api/mantenedor/EditarPlan", {
    //            method: 'PUT',
    //            headers: {
    //                'Content-Type': 'application/json;charset=utf-8'
    //            },
    //            body: JSON.stringify(plan)
    //        })
    //    }

    //    if (response.ok) {
    //        await obtenerPlanes();
    //        setPlan(modeloPlan)
    //        setVerModal(!verModal);

    //    } else {
    //        alert("error al guardar")
    //    }

    //}

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Busqueda Clientes
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Cliente:</Label>
                                        <Input bsSize="sm" value={busqueda} onChange={(e) => setBusqueda(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscarClientes}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col sm={12}>
                                    <div className="table table-responsive">
                                        <DataTable
                                            columns={columnsBusqueda}
                                            data={headers}
                                            progressPending={pendienteBusqueda}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                            paginationPerPage={10}
                                            noDataComponent="No se encontraron resultados"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="xl" isOpen={verModal} className="modal-dialog-scrollable">
                <ModalHeader>
                    Mantenedor Header
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} name="rut" bsSize="sm" onChange={handleChange} value={header.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} name="nombres" bsSize="sm" onChange={handleChange} defaultValue={header.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido Paterno:</Label>
                                <Input style={{ fontSize: "12px" }} name="apellidoP" bsSize="sm" onChange={handleChange} defaultValue={header.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido Materno :</Label>
                                <Input style={{ fontSize: "12px" }} name="apellidoM" bsSize="sm" onChange={handleChange} defaultValue={header.apellidoM} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} name="telefono" bsSize="sm" onChange={handleChange} defaultValue={header.telefono} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut Pagador :</Label>
                                <Input style={{ fontSize: "12px" }} name="rutPagador" bsSize="sm" onChange={handleChange} defaultValue={header.rutPagador} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Forma Pago :</Label>
                                <Input bsSize="sm" type={"select"} name="idFormaPago" onChange={handleChange} value={header.idFormaPago} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        formasPago.map((item) => (<option key={item.idFormaPago} value={item.idFormaPago}>{item.descripcion}</option>))
                                    }

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="diaCobro" bsSize="sm" onChange={handleChange} defaultValue={header.diaCobro} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br></br>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={() => setVerModal(!verModal)}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default MantenedorHeader;