import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";
import * as XLSX from "xlsx"
import { Navigate } from "react-router-dom"


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloInicio = [{
    idContrato: "",
    rut: "",
    nombre: "",
    nombrePlan: "",
    montoPlan: "",
    saldo: "",
    estadoContrato: "",
    observaciones: "",
    fecha: "",
    estadoFinal: "",
    tipoDeuda: "",
    tipoModificacion: "",
    usuario: ""
}]

const ReporteModificaciones = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [pendiente, setPendiente] = useState(false);
    const [cambios, setCambios] = useState(modeloInicio)

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const buscar = () => {

        setPendiente(true)
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)

        const api = fetch(`api/mantenedor/ReporteCambios?fechaInicio=${_fechaInicio}&fechaFin=${_fechaFin}`)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setCambios(data);
            }).catch((error) => {
                setCambios([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    //const columns = [
    //    {
    //        name: 'Fecha Registro',
    //        selector: row => row.fechaRegistro,
    //    },
    //    {
    //        name: 'Numero Venta',
    //        selector: row => row.numeroDocumento,
    //    },
    //    {
    //        name: 'Tipo Documento',
    //        selector: row => row.tipoDocumento,
    //    },
    //    {
    //        name: 'Documento Cliente',
    //        selector: row => row.documentoCliente,
    //    },
    //    {
    //        name: 'Nombre Cliente',
    //        selector: row => row.nombreCliente,
    //    },
    //    {
    //        name: 'Sub Total Venta',
    //        selector: row => row.subTotalVenta,
    //    },
    //    {
    //        name: 'Impuesto Total Venta',
    //        selector: row => row.impuestoTotalVenta,
    //    },
    //    {
    //        name: 'Total Venta',
    //        selector: row => row.totalVenta,
    //    },
    //    {
    //        name: 'Producto',
    //        sortable: true,
    //        grow: 2,
    //        maxWidth: '600px',
    //        selector: row => row.producto,
    //    },
    //    {
    //        name: 'Cantidad',
    //        selector: row => row.cantidad,
    //    },
    //    {
    //        name: 'Precio',
    //        selector: row => row.precio,
    //    },
    //    {
    //        name: 'Total',
    //        selector: row => row.total,
    //    },
    //];

    const columns = [
        {
            name: <div>Id Contrato</div>,
            selector: row => row.idContrato,
            sortable: true
        },
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            grow: 2
        },
        {
            name: <div>Nombre</div>,
            selector: row => row.nombre,
            sortable: true,
            grow: 2

        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.nombrePlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Monto Plan</div>,
            selector: row => row.montoPlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Saldo</div>,
            selector: row => row.saldo,
            sortable: true
        },
        {
            name: <div>Estado Contrato</div>,
            selector: row => row.estadoContrato,
            sortable: true
        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true
        },
        {
            name: <div>Fecha</div>,
            selector: row => row.fecha,
            sortable: true,
            grow: 2,
            wrap: true

        },
        {
            name: <div>Estado Final</div>,
            selector: row => row.estadoFinal,
            sortable: true,
            grow: 2,
            wrap: true

        },
        {
            name: <div>Tipo Deuda</div>,
            selector: row => row.tipoDeuda,
            sortable: true,
            grow: 2,
            wrap: true

        },
        {
            name: <div>Tipo Cambio</div>,
            selector: row => row.tipoModificacion,
            sortable: true,
            grow: 2,
            wrap: true

        },
        {
            name: <div>Usuario</div>,
            selector: row => row.usuario,
            sortable: true,
            grow: 2,
            wrap: true

        },

    ];

    //const customStyles = {
    //    headCells: {
    //        style: {
    //            fontSize: '13px',
    //            fontWeight: 800,
    //        },
    //    },
    //    headRow: {
    //        style: {
    //            backgroundColor: "#eee",
    //        }
    //    }
    //};

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(cambios);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Modificaciones.xlsx")
    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Reporte Modificaciones
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            onChange={(date) => setFechaInicio(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaFin}
                                            onChange={(date) => setFechaFin(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={cambios}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                        paginationPerPage={10}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </>
    )
}

export default ReporteModificaciones;