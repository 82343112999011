import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol:0,
        descripcion: ""
    }
}
const NavBar = () => {

    const { user} = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])
    return (
        
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <img className="card-img-top" src={"./imagen/logo2.png"} />
                {/*<div className="sidebar-brand-icon">*/}
                {/*    <i className="fas fa-desktop"></i>*/}
                {/*</div>*/}
                {/*<div className="sidebar-brand-text mx-3">Rest 911</div>*/}
            </Link>


            <hr className="sidebar-divider my-0" />

            {/*{*/}
            {/*    (dataUser.idRolNavigation.descripcion == "Administrador") &&*/}
            {/*    <li className="nav-item">*/}
            {/*        <NavLink to="/dashboard" className="nav-link" >*/}
            {/*            <i className="fas fa-fw fa-tachometer-alt"></i>*/}
            {/*            <span>Dashboard</span>*/}
            {/*        </NavLink>*/}
            {/*    </li>*/}
            {/*}*/}
           

            <hr className="sidebar-divider" />
            {
                (dataUser.idRolNavigation.descripcion == "Administrador") &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUsuario"
                        aria-expanded="true" aria-controls="collapseUsuario">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Administracion</span>
                    </a>
                    <div id="collapseUsuario" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink to="/usuario" className="collapse-item">Usuarios</NavLink>
                        </div>
                    </div>
                </li>
            }
            
            {/*{*/}
            {/*    (dataUser.idRolNavigation.descripcion == "Administrador") &&*/}
            {/*    <li className="nav-item">*/}
            {/*        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInventario"*/}
            {/*            aria-expanded="true" aria-controls="collapseInventario">*/}
            {/*            <i className="fas fa-fw fa-box"></i>*/}
            {/*            <span>Inventario</span>*/}
            {/*        </a>*/}
            {/*        <div id="collapseInventario" className="collapse" aria-labelledby="headingUtilities"*/}
            {/*            data-parent="#accordionSidebar">*/}
            {/*            <div className="bg-white py-2 collapse-inner rounded">*/}
            {/*                <NavLink to="/producto" className="collapse-item">Productos</NavLink>*/}
            {/*                <NavLink to="/categoria" className="collapse-item">Categorias</NavLink>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*}*/}
           

            {/*<li className="nav-item">*/}
            {/*    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseVenta"*/}
            {/*        aria-expanded="true" aria-controls="collapseVenta">*/}
            {/*        <i className="fas fa-fw fa-tag"></i>*/}
            {/*        <span>Ventas</span>*/}
            {/*    </a>*/}
            {/*    <div id="collapseVenta" className="collapse" aria-labelledby="headingUtilities"*/}
            {/*        data-parent="#accordionSidebar">*/}
            {/*        <div className="bg-white py-2 collapse-inner rounded">*/}
            {/*            <NavLink to="/venta" className="collapse-item">Nueva Venta</NavLink>*/}
            {/*            <NavLink to="/historialventa" className="collapse-item">Historial Venta</NavLink>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</li>*/}

            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCobranza"
                    aria-expanded="true" aria-controls="collapseCobranza">
                    <i className="fas fa-fw fa-tag"></i>
                    <span>Recaudación</span>
                </a>
                <div id="collapseCobranza" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink to="/clientes" className="collapse-item">Clientes Cobranza</NavLink>
                        <NavLink to="/clienteRecurrente" className="collapse-item">Clientes Recurrente</NavLink>
                        <NavLink to="/clienteBaja" className="collapse-item">Clientes De Baja</NavLink>
                        <NavLink to="/clientePrepago" className="collapse-item">Clientes Prepago</NavLink>
                        <NavLink to="/clienteVentaNueva" className="collapse-item">Clientes Venta Nueva</NavLink>
                    </div>
                </div>
            </li>

            <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseExpress"
                    aria-expanded="true" aria-controls="collapseExpress">
                    <i className="fas fa-fw fa-tag"></i>
                    <span>Consulta Express</span>
                </a>
                <div id="collapseExpress" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <NavLink to="/consultaExpress" className="collapse-item">Consulta</NavLink> 
                    </div>
                </div>
            </li>
            

            {
                (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor" || dataUser.idRolNavigation.descripcion == "Digitador") &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMantenedores"
                        aria-expanded="true" aria-controls="collapseMantenedores">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Mantenedores</span>
                    </a>
                    <div id="collapseMantenedores" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                                <NavLink to="/planes" className="collapse-item">Mantenedor Planes</NavLink>
                                <NavLink to="/mantenedorHeader" className="collapse-item">Mantenedor Header</NavLink>
                                {/*<NavLink to="/ventasNuevas" className="collapse-item">Ventas Nuevas</NavLink>*/}
                                <NavLink to="/formVentas" className="collapse-item">Ventas Nuevas</NavLink>
                                <NavLink to="/vendedor" className="collapse-item">Vendedores</NavLink>
                            {/*<NavLink to="/historialventa" className="collapse-item">Historial Venta</NavLink>*/}
                        </div>
                    </div>
                </li>
            }


            {(dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporte"
                        aria-expanded="true" aria-controls="collapseReporte">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Reportes</span>
                    </a>
                    <div id="collapseReporte" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <NavLink to="/reporteModificaciones" className="collapse-item">Modificaciones</NavLink>
                            <NavLink to="/reporteVentaNueva" className="collapse-item">Ventas Nuevas</NavLink>
                            <NavLink to="/reporteCierreMes" className="collapse-item">Cierre de mes</NavLink>
                            <NavLink to="/reporteBajas" className="collapse-item">Bajas</NavLink>
                            <NavLink to="/reportePrepagos" className="collapse-item">Prepagos</NavLink>
                            <NavLink to="/reporteMontoPlan" className="collapse-item">Monto Plan</NavLink>
                        </div>
                    </div>
                </li>
            }
            


            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>
        )
}

export default NavBar;