import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloRetencion = {
    idRetencion: 0,
    idContrato: 0,
    monto: 0,
    fechaInicio: "",
    cantidadMeses: 0,
    usuarioCreacion: "",
    fechaCreacion: "",
    observaciones: "",
    activo: false
}

const modeloContrato = {
    idCliente: 0,
    rut: "",
    nombres: "",
    apellidoP: "",
    apellidoM: "",
    telefono: "",
    estadoContrato: "",
    observaciones: "",
    idPlan: 0,
    plan: "",
    montoPlan: 0,
    rutPagador: "",
    fechaIngreso: "",
    diaCobro: 0,
    formaPago: "",
    montoDeuda: 0,
    mesesDeuda: 0,
    categoriaDeuda: "",
    idEstadoContrato: 0,
    idContrato: 0
}

const ClienteBaja = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [clientes, setClientes] = useState([])
    const [pendienteBusqueda, setPendienteBusqueda] = useState(false);
    const [detalleCliente, setDetalleCliente] = useState({});
    const [verModal, setVerModal] = useState(false)
    const [busqueda, setBusqueda] = useState("")
    const [contrato, setContrato] = useState(modeloContrato);
    const [retencion, setRetencion] = useState(modeloRetencion);
    const [verModalRetencion, setVerModalRetencion] = useState(false)
    const [historial, setHistorial] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalHistorial, setVerModalHistorial] = useState(false)

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const handleChange = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        value = e.target.value
        setContrato({
            ...contrato,
            [e.target.name]: value
        })
    }

    const mostrarModal = (data) => {
        setDetalleCliente(data)
        setVerModal(!verModal);
    }

    const mostrarModalRetencion = (data) => {
        setContrato(data);
        setRetencion(data.listaRetenciones);
        setVerModalRetencion(!verModalRetencion);
    }

    const buscarClientes = () => {
        setPendienteBusqueda(true)
        
        const api = fetch("api/contrato/ListarClientesBaja/" + busqueda)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    setPendienteBusqueda(false)
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }

                setClientes(data);
                setPendienteBusqueda(false);
                
                //setRetencion(data.listaRetenciones);

            }).catch((error) => {
                setPendienteBusqueda(false)
                setClientes([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })

    }

    const buscarHistorial = (data) => {
        //const api = fetch("api/mantenedor/ListaPlanes/" + modeloContrato.idContrato)
        const api = fetch("api/mantenedor/BuscarHistorial/" + data.idContrato)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setHistorial(data);
            }).catch((error) => {
                setHistorial([]);
            })

    }

    const mostrarModalHistorial = (data) => {

        buscarHistorial(data);
        setPendiente(false)

        setVerModalHistorial(!verModalHistorial);
    }

    const cerrarModalHistorial = () => {
        buscarHistorial([]);
        setVerModalHistorial(!verModalHistorial);
    }

    const columnsRetencion = [
        {
            name: <div>Fecha Creación</div>,
            selector: row => row.fechaCreacion,
            sortable: true,
            wrap: true,

        },
        {
            name: <div>Monto</div>,
            selector: row => row.monto,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Fecha Inicio</div>,
            selector: row => row.fechaInicio,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Meses</div>,
            selector: row => row.cantidadMeses,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Usuario Creación</div>,
            selector: row => row.usuarioCreacion,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Activo</div>,
            selector: row => row.activo,
            sortable: true,
            wrap: true,
            cell: row => {
                let clase;
                clase = row.activo ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.activo ? "Activa" : "No Activa"}</span>
                )
            }

        },
    ]

    const columns = [
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 2,
            style: row => 10 == 10 ? { color: 'red' } : { color: 'green' }
        },
        {
            name: 'Nomple Plan',
            selector: row => row.nombrePlan,
            sortable: true,
            grow: 2

        },
        {
            name: 'Monto Plan',
            selector: row => row.montoPlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Estado Contrato</div>,
            selector: row => row.estadoContrato,
            sortable: true
        },
        {
            name: <div>Tipo Deuda</div>,
            selector: row => row.tipoDeuda,
            sortable: true
        },
        {
            name: <div>Estado Final</div>,
            selector: row => row.estadoFinal,
            sortable: true
        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true,
            grow: 4,
            wrap: true

        },
        {
            name: <div>Tipo Cambio</div>,
            selector: row => row.tipoLog,
            sortable: true
        },
        {
            name: 'Usuario',
            selector: row => row.usuario,
            sortable: true
        },

    ];

    const columnsBusqueda = [
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Cliente</div>,
            selector: row => row.nombres + ' ' + row.apellidoP,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Teléfono</div>,
            selector: row => row.telefono,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Estado</div>,
            selector: row => row.estadoContrato,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.plan,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => mostrarModal(row)}>
                        <i className="fa fa-eye" aria-hidden="true"></i> Ver detalle
                    </Button>

                </>               
            ),
        },
        //{
        //    name: '',
        //    cell: row => (
        //        <Row>
        //            <Col sm={3}>
        //                {(administrador) ? (
        //                    <Button color="danger" size="sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Retención"
        //                        onClick={() => mostrarModalRetencion(row)}>
        //                        <i className="fas fa-percentage"></i>
        //                    </Button>
        //                ) : (<Button disabled color="danger" size="sm"

        //                >
        //                    <i className="fas fa-percentage"></i>
        //                </Button>)}
        //            </Col>
        //            <Col sm={3}>
        //                <Button color="primary" size="sm" className="mr-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Historial"
        //                    block onClick={() => mostrarModalHistorial()}>
        //                    <i className="fas fa-percentage"></i>
        //                </Button>
        //            </Col>
        //            <Col sm={3}>
        //            </Col>
        //        </Row>
        //    ),
        //},
        {
            name: '',
            cell: row => (
                <>
                    <Row>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="primary" size="sm" className="mr-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Historial"
                                    onClick={() => mostrarModalHistorial(row)}
                                >
                                    <i className="fas fa-info"></i>
                                </Button>
                            ) : (<Button disabled color="primary" size="sm" className="mr-2"
                                    
                            >
                                    <i className="fas fa-info"></i>
                            </Button>)}
                        </Col>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="danger" size="sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Retención"
                                    onClick={() => mostrarModalRetencion(row)}

                                >
                                    <i className="fas fa-percentage"></i>
                                </Button>
                            ) : (<Button disabled color="danger" size="sm"

                            >
                                <i className="fas fa-percentage"></i>
                            </Button>)}
                        </Col>
                        <Col sm={3}>
                            
                        </Col>
                    </Row>
                </>
            ),

        }
    ];


    

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Busqueda Clientes Baja
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Cliente:</Label>
                                        <Input bsSize="sm" value={busqueda} onChange={(e) => setBusqueda(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscarClientes}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col sm={12}>
                                    <div className="table table-responsive">
                                        <DataTable
                                            columns={columnsBusqueda}
                                            data={clientes}
                                            progressPending={pendienteBusqueda}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                            paginationPerPage={10}
                                            noDataComponent="No se encontraron resultados"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="xl" isOpen={verModal} className="modal-dialog-scrollable">
                <ModalHeader>
                    Detalle Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModal(!verModal)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalRetencion} className="modal-dialog-scrollable">
                <ModalHeader>
                    Retenciones Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>

                    {(retencion.length > 0) ? (
                        <Row>
                            <Col sm={12}>
                                <div className="table table-responsive table-striped">
                                    <DataTable
                                        columns={columnsRetencion}
                                        data={retencion}
                                        //progressPending={pendiente}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        customStyles={customStyles}
                                        paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                        paginationPerPage={5}
                                        highlightOnHover
                                        noDataComponent="No se encontraron resultados"
                                    />
                                </div>
                            </Col>
                        </Row>
                    ) : (<Row>
                        <Row>
                            <Col sm={12}>
                                <Col sm={12}>
                                    Sin retenciones previas.
                                </Col>
                            </Col>
                        </Row>
                    </Row>
                    )
                    }
                    <hr></hr>
                    
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalRetencion(!verModalRetencion)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalHistorial}>
                <ModalHeader>
                    Historial
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <div className="table table-responsive">
                            <DataTable
                                columns={columns}
                                data={historial}
                                progressPending={pendiente}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                paginationPerPage={5}
                                noDataComponent="No se encontraron resultados"
                            />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalHistorial}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
        )

}

export default ClienteBaja;