import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloContrato = {
    idCliente: 0,
    rut: "",
    nombres: "",
    apellidoP: "",
    apellidoM: "",
    telefono: "",
    estadoContrato: "",
    observaciones: "",
    idPlan: 0,
    plan: "",
    montoPlan: 0,
    rutPagador: "",
    fechaIngreso: "",
    diaCobro: 0,
    formaPago: "",
    montoDeuda: 0,
    mesesDeuda: 0,
    categoriaDeuda: "",
    idEstadoContrato: 0,
    idContrato: 0
}

const modeloPlan = {
    idPlan: 0,
    montoPlan: 0,
    idEstadoContrato: 0,
    observaciones: "",
    saldo: 0
}

const ClientePrepago = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerPlanes();
    }, [])

    const [contrato, setContrato] = useState(modeloContrato);
    const [clientes, setClientes] = useState([])
    const [pendienteBusqueda, setPendienteBusqueda] = useState(false);
    const [detalleCliente, setDetalleCliente] = useState({});
    const [verModal, setVerModal] = useState(false)
    const [busqueda, setBusqueda] = useState("")
    const [verModalUpdate, setVerModalUpdate] = useState(false)
    const [plan, setPlan] = useState(modeloPlan);
    const [planes, setPlanes] = useState([]);
    const [verModalAbierto, setVerModalAbierto] = useState("");
    const [historial, setHistorial] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalHistorial, setVerModalHistorial] = useState(false)

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const handleChange = (e) => {
        let value;// = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;
        value = e.target.value
        setContrato({
            ...contrato,
            [e.target.name]: value
        })

        value = e.target.value
        setPlan({
            ...plan,
            [e.target.name]: value
        })
    }

    const obtenerPlanes = async () => {
        let response = await fetch("api/mantenedor/ListaPlanes");
        if (response.ok) {
            let data = await response.json()
            setPlanes(data)
        }
    }

    const buscarHistorial = () => {
        //const api = fetch("api/mantenedor/ListaPlanes/" + modeloContrato.idContrato)
        const api = fetch("api/mantenedor/BuscarHistorial/" + contrato.idContrato)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setHistorial(data);
            }).catch((error) => {
                setHistorial([]);
            })

    }

    const mostrarModalHistorial = (desde) => {

        if (desde == 1) {
            setVerModalUpdate(!verModalUpdate)
            setVerModalAbierto("plan")
        }
        //else if (desde == 2) {
        //    setVerModalUpdateEstado(!verModalUpdateEstado)
        //    setVerModalAbierto("estado")
        //}
        //else {
        //    setVerModalCambioSaldo(!verModalCambioSaldo)
        //    setVerModalAbierto("saldo")
        //}
        buscarHistorial();
        setPendiente(false)

        setVerModalHistorial(!verModalHistorial);
    }

    const actualizarContrato = () => {


        let Contrato = {
            idTipo: 2,
            idContrato: contrato.idContrato,
            idPlan: plan.idPlan,
            montoPlan: plan.montoPlan,
            //idEstadoContrato: plan.idEstadoContrato,
            observaciones: plan.observaciones,
            idUsuario: JSON.parse(user).idUsuario
        }

        if (plan.idPlan == undefined || plan.idPlan == 0 || plan.montoPlan == undefined || plan.montoPlan == 0 || plan.observaciones == undefined || plan.observaciones == "") {
            //|| plan.idEstadoContrato == undefined || plan.idEstadoContrato == 0 
            Swal.fire(
                'Opps!',
                'Es necesario llenar todos los campos.',
                'error'
            )
        }
        else {

            const api = fetch("api/mantenedor/ActualizarContrato", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Contrato)
            })
                .then((response) => {
                    return response.ok;
                })
                .then((dataJson) => {
                    //reestablecer();
                    //var data = dataJson;
                    buscarClientes();
                    setPlan([]);
                    setVerModalUpdate(!verModalUpdate);
                    Swal.fire(
                        'Actualización exitosa!',
                        //'Numero de venta : ' + data.numeroDocumento,
                        //'success'
                    )

                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No se pudo realizar la modificación',
                        'error'
                    )
                    //console.log("No se pudo enviar la venta ", error)
                })
        }

    }

    const mostrarModal = (data) => {
        setDetalleCliente(data)
        setVerModal(!verModal);
    }

    const mostrarModalUpdate = (data) => {
        setContrato(data);
        setVerModalUpdate(!verModalUpdate);
    }

    const buscarClientes = () => {
        setPendienteBusqueda(true)

        const api = fetch("api/contrato/ListarClientesPrepago/" + busqueda)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    setPendienteBusqueda(false)
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setClientes(data);
                setPendienteBusqueda(false)
                //setRetencion(data.listaRetenciones);

            }).catch((error) => {
                setPendienteBusqueda(false)
                setClientes([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })

    }

    const columnsBusqueda = [
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Cliente</div>,
            selector: row => row.nombres + ' ' + row.apellidoP,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Teléfono</div>,
            selector: row => row.telefono,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Estado</div>,
            selector: row => row.estadoContrato,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Tipo Contrato</div>,
            selector: row => row.tipoContrato,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.plan,
            sortable: true,
            wrap: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => mostrarModal(row)}>
                        <i className="fa fa-eye" aria-hidden="true"></i> Ver detalle
                    </Button>

                </>
            ),
        },
        {
            name: '',
            cell: row => (
                <>
                    <Row>
                        <Col sm={3}>
                            {(administrador) ? (
                                <Button color="primary" size="sm" className="mr-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Modificación Plan"
                                    onClick={() => mostrarModalUpdate(row)}
                                >
                                    <i className="fas fa-pen-alt"></i>
                                </Button>
                            ) : (<Button disabled color="primary" size="sm" className="mr-2"

                            >
                                <i className="fas fa-pen-alt"></i>
                            </Button>)}
                        </Col>
                    </Row>
                </>
            ),

        },
    ];

    const cerrarModalHistorial = () => {

        if (verModalAbierto == "plan") {
            setVerModalUpdate(!verModalUpdate)
        }

        setVerModalHistorial(!verModalHistorial);
    }

    const columns = [
        {
            name: <div>Tipo Cambio</div>,
            selector: row => row.tipoLog,
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 2
        },
        {
            name: 'Nomple Plan',
            selector: row => row.nombrePlan,
            sortable: true,
            grow: 2

        },
        {
            name: 'Monto Plan',
            selector: row => row.montoPlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Saldo</div>,
            selector: row => row.saldo,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Estado Contrato</div>,
            selector: row => row.estadoContrato,
            sortable: true
        },
        {
            name: <div>Tipo Deuda</div>,
            selector: row => row.tipoDeuda,
            sortable: true
        },
        {
            name: <div>Estado Final</div>,
            selector: row => row.estadoFinal,
            sortable: true
        },
        {
            name: <div>Observaciones</div>,
            selector: row => row.observaciones,
            sortable: true,
            grow: 2,
            wrap: true

        },
        {
            name: 'Usuario',
            selector: row => row.usuario,
            sortable: true
        },

    ];

    const columns2 = [
        {
            name: <div>Fecha Pago</div>,
            selector: row => row.fechaPago,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Forma Pago</div>,
            selector: row => row.formaPago,
            sortable: true,
            wrap: true
        },
        {
            name: <div>N° Transacción</div>,
            selector: row => row.numeroTransaccion,
            sortable: true,
            wrap: true
        },
        {
            name: 'Pago',
            selector: row => row.montoPago,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Clasif. Pago</div>,
            selector: row => row.clasificacionMontoPago,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Clasif. Descuento</div>,
            selector: row => row.clasificacionDescuento,
            sortable: true,
            wrap: true
        },
        {
            name: 'Saldo',
            selector: row => row.saldo,
            sortable: true,
            wrap: true
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Busqueda Clientes Prepago
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Cliente:</Label>
                                        <Input bsSize="sm" value={busqueda} onChange={(e) => setBusqueda(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscarClientes}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col sm={12}>
                                    <div className="table table-responsive">
                                        <DataTable
                                            columns={columnsBusqueda}
                                            data={clientes}
                                            progressPending={pendienteBusqueda}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                            paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                            paginationPerPage={10}
                                            noDataComponent="No se encontraron resultados"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr></hr>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="xl" isOpen={verModal} className="modal-dialog-scrollable">
                <ModalHeader>
                    Detalle Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Reactivación :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.fechaReactivacion} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Tipo Contrato :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={detalleCliente.tipoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                            <div className="table table-responsive table-striped">
                                <DataTable
                                    columns={columns2}
                                    data={detalleCliente.listaPagos}
                                    //progressPending={pendiente}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    customStyles={customStyles}
                                    paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                    paginationPerPage={5}
                                    highlightOnHover
                                    noDataComponent="No se encontraron resultados"
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModal(!verModal)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalUpdate} className="modal-dialog-scrollable">
                <ModalHeader>
                    Módificación Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.nombres} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.apellidoP} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.telefono} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.plan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Día Cobro :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.diaCobro} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.montoDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Meses Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.mesesDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup >
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Estado : </Label>&nbsp;
                                {/*<Label className="font-weight-bold">{detalleCliente.estadoContrato}</Label>*/}
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={contrato.estadoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nuevo Plan</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" type={"select"} name="idPlan" onChange={handleChange} defaultValue={plan.idPlan}>
                                    <option value={0}>Seleccionar</option>
                                    {
                                        planes.map((item) => {
                                            if (item.activo)
                                                return (<option key={item.idPlan} value={item.idPlan}>{item.nombrePlan}</option>)
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Mensual</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" name="montoPlan" onChange={handleChange} defaultValue={plan.montoPlan} type="number" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Observaciones :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="lg" name="observaciones" onChange={handleChange} defaultValue={plan.observaciones} type="textarea" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={3}>
                            <Button size="sm" color="primary" block onClick={actualizarContrato} >Guardar</Button>
                        </Col>
                        <Col sm={2}>
                            <Button size="sm" color="info" block onClick={() => mostrarModalHistorial(1)}>Historial</Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalUpdate(!verModalUpdate)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalHistorial}>
                <ModalHeader>
                    Historial
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <div className="table table-responsive">
                            <DataTable
                                columns={columns}
                                data={historial}
                                progressPending={pendiente}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                paginationPerPage={5}
                                noDataComponent="No se encontraron resultados"
                            />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalHistorial}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )

}

export default ClientePrepago;