import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter } from "reactstrap"
import Swal from 'sweetalert2'

import { UserContext } from '../context/UserProvider';
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom"

const modeloPlan = {
    idPlan: 0,
    nombrePlan: "",
    codShaman: "",
    activo: true
}

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Planes = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    let administrador = false;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor" || dataUser.idRolNavigation.descripcion == "Digitador") ? (administrador = true) : (administrador = false);

    const [plan, setPlan] = useState(modeloPlan);
    const [pendiente, setPendiente] = useState(true);
    const [planes, setPlanes] = useState([]);
    const [verModal, setVerModal] = useState(false);

    const handleChange = (e) => {
        let value = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;

        setPlan({
            ...plan,
            [e.target.name]: value
        })
    }

    const obtenerPlanes = async () => {
        let response = await fetch("api/mantenedor/ListaPlanesForm");

        if (response.ok) {
            let data = await response.json()
            setPlanes(data)
            setPendiente(false)
        }
    }

    useEffect(() => {
        obtenerPlanes();
    }, [])


    const columns = [
        {
            name: 'Descripcion',
            selector: row => row.nombrePlan,
            sortable: true,
        },
        {
            name: 'Código Shaman',
            selector: row => row.codShaman,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row.activo,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.activo ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.activo ? "Activo" : "No Activo"}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarPlan(row.idPlan)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const abrirEditarModal = (data) => {
        setPlan(data);
        setVerModal(!verModal);
    }

    const cerrarModal = () => {
        setPlan(modeloPlan)
        setVerModal(!verModal);
    }

    const guardarCambios = async () => {

        let response;
        if (plan.idPlan == 0) {
            response = await fetch("api/mantenedor/GuardarPlan", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(plan)
            })

        } else {
            response = await fetch("api/mantenedor/EditarPlan", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(plan)
            })
        }

        if (response.ok) {
            if (plan.idPlan == 0) {
                Swal.fire(
                    'Creación!',
                    'El plan creado con éxito.',
                    'success'
                )
            } else {
                Swal.fire(
                    'Modificación!',
                    'El plan se ha modificado éxito.',
                    'success'
                )
            }
            
            await obtenerPlanes();
            setPlan(modeloPlan)
            setVerModal(!verModal);

        } else {
            alert("error al guardar")
        }

    }


    const eliminarPlan = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desesa eliminar este plan",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/mantenedor/EliminarPlan/" + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {

                            obtenerPlanes();

                            Swal.fire(
                                'Eliminado!',
                                'El plan fue eliminado.',
                                'success'
                            )
                        }
                    })

            }
        })
    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Planes
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Plan</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={planes}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle Categoria
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Descripción</Label>
                        <Input bsSize="sm" name="nombrePlan" onChange={handleChange} value={plan.nombrePlan} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Código Shaman</Label>
                        <Input bsSize="sm" name="codShaman" onChange={handleChange} value={plan.codShaman} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Estado</Label>
                        <Input bsSize="sm" type={"select"} name="activo" onChange={handleChange} value={plan.activo} >
                            <option value={true}>Activo</option>
                            <option value={false}>No Activo</option>
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>



    )
}

export default Planes;