import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";
import * as XLSX from "xlsx"
import { Navigate } from "react-router-dom"


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloInicio = [{
    idContrato: "",
    rut: "",
    nombre: "",
    nombrePlan: "",
    montoPlan: "",
    saldo: "",
    estadoContrato: "",
    observaciones: "",
    fecha: "",
    estadoFinal: "",
    tipoDeuda: "",
    tipoModificacion: "",
    usuario: ""
}]

const ReporteVentaNueva = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [pendiente, setPendiente] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [excelVentas, setVentasExcel] = useState("")

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const buscar = () => {

        setPendiente(true)
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)

        const api = fetch(`api/mantenedor/ReporteVentaNueva?fechaInicio=${_fechaInicio}&fechaFin=${_fechaFin}`)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                console.log(data)

                setVentasExcel(data);

                // console.log(this.state);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            // console.log(d)
        });
    }

    const procesarVentas = () => {

        const api = fetch(`api/mantenedor/VentasNuevas`)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const columns = [
        {
            name: <div>Id Contrato</div>,
            selector: row => row.idContrato,
            sortable: true
        },
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            grow: 2
        },
        {
            name: <div>Nombre</div>,
            selector: row => row.nombre,
            sortable: true,
            grow: 2

        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.nombrePlan,
            sortable: true,
            grow: 1
        },
        {
            name: <div>Monto Plan</div>,
            selector: row => row.montoMensualPlan,
            sortable: true,
            grow: 1
        },  
         {
            name: <div>Fecha Venta</div>,
             selector: row => row.fechaIngreso,
            sortable: true,
            grow: 1
        }   
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Ventas Nuevas.xlsx")
    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Reporte Ventas Nuevas
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            onChange={(date) => setFechaInicio(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaFin}
                                            onChange={(date) => setFechaFin(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                        paginationPerPage={10}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </>
    )
}

export default ReporteVentaNueva;