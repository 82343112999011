//import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
//import { UserContext } from '../context/UserProvider';
//import React, { useContext } from 'react';
import { Navigate } from "react-router-dom"
import DatePicker from "react-datepicker";



import { useState } from "react";
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloVenta = {

    idRegistro: 0,
    fechaVenta: "",
    rut: "",
    primerNombre: "",
    segundoNombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    telefono: "",
    correo: "",
    region: "",
    ciudad: "",
    domicilio: "",
    segmento: "",
    rubro: "",
    nombreEmpresa: "",
    tipoServicio: "",
    canalVenta: "",
    tipoEjecutivo: "",
    nombreEjecutivo: "",
    nombrePlan: "",
    numBeneficiarios: "",
    montoPlan: "",
    copago: "",
    valorCopago: "",
    rutPagador: "",
    primerPago:"",
    formaPago: "",
    frecuencia: "",
    iva: "",
    empFacturacion: "",
    formaFacturacion: "",
    tipoVenta: "",
    cantMesesPrepago: "",
    descuento: "",
    formaVenta: "",
    idUsuario: "",
    montoPagar: ""
}

const FormVentas = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)


    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [venta, setVenta] = useState(modeloVenta);
    const [ventas, setVentas] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [planes, setPlanes] = useState([]);
    const [vendedores, setVendedores] = useState([]);

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Digitador") ? (administrador = true) : (administrador = false);

    const handleChange = (e) => {

        console.log(e.target.value)

        let value;

        value = e.target.value;

        setVenta({
            ...venta,
            [e.target.name]: value
        })
    }

    const obtenerVentasPendientes = async () => {

        var usuario = JSON.parse(user).nombre;
        let response = await fetch("api/contrato/ListaVentasNuevas/" + usuario);

        if (response.ok) {
            let data = await response.json()
            setVentas(data)
            setPendiente(false)
        }
    }

    const obtenerPlanes = async () => {
        let response = await fetch("api/mantenedor/ListaPlanesForm");
        if (response.ok) {
            let data = await response.json()
            setPlanes(data)
        }
    }

    const obtenerVendedores = async () => {
        let response = await fetch("api/mantenedor/listarVendedoresFormVentas");
        if (response.ok) {
            let data = await response.json()
            setVendedores(data)
        }
    }

    useEffect(() => {
        obtenerVentasPendientes();
    }, [])

    const columns = [
        {
            name: 'Rut',
            selector: row => row.rut,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.primerNombre + " " + row.apellidoPaterno,
            sortable: true,
        },
        {
            name: 'Plan',
            selector: row => row.nombrePlan,
            sortable: true,
        },
        {
            name: 'Segmento',
            selector: row => row.segmento,
            sortable: true,
        },
        {
            name: 'Forma Pago',
            selector: row => row.formaPago,
            sortable: true,
        },
        {
            name: 'Monto Plan',
            selector: row => "$ " + row.montoPlan,
            sortable: true,
        },
        {
            name: 'Usuario',
            selector: row => row.nombreUsuario,
            sortable: true,
        },
        {
            name: 'Tipo',
            selector: row => row.formaVenta,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.formaVenta ==  "Automática" ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.formaVenta}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarVenta(row.idRegistro)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>

                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const language = {
        emptyTable: "No hay información",
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    const abrirModal = () => {
        obtenerPlanes();
        obtenerVendedores();
        var fecha = new Date()
        setFechaInicio(fecha)
        setVenta(modeloVenta)
        setVerModal(!verModal);
    }

    const abrirEditarModal = (data) => {
        setVenta(data);
        obtenerPlanes();
        obtenerVendedores();
        var fecha = new Date(data.fechaVenta)
        setFechaInicio(fecha)
        setVerModal(!verModal);
    }

    const cerrarModal = () => {
        setVerModal(!verModal);
    }

    const error = {
        color: 'red',
        fontWeight: 'bold',
        fontSize: '14'
    }  

    var Fn = {
    validaRut: function(rutCompleto) {
        if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto))
            return false;
        var tmp = rutCompleto.split('-');
        var digv = tmp[1];
        var rut = tmp[0];
        if (digv == 'K') digv = 'k';
        return (Fn.dv(rut) == digv);
    },
        dv: function(T) {
            var M = 0,
                S = 1;
            for (; T; T = Math.floor(T / 10))
                S = (S + T % 10 * (9 - M++ % 6)) % 11;
            return S ? S - 1 : 'k';
        }
    }

    function validarEmail(valor) {
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)) {
            return true
        } else {
            return false
        }
    }

    const guardarVenta = async () => {

        let response;
        venta.fechaVenta = fechaInicio;
        venta.idUsuario = dataUser.nombre;

        if (venta.idRegistro == 0) {
            

            if (venta.rut == "" || venta.primerNombre == "" || venta.apellidoPaterno == "" || venta.telefono == "" || venta.correo == ""
                || venta.region == 0 || venta.ciudad == 0 || venta.domicilio == "" || venta.segmento == 0 || venta.rubro == 0
                || venta.nombreEmpresa == 0 || venta.tipoServicio == 0 || venta.canalVenta == 0 || venta.tipoEjecutivo == 0 ||
                venta.nombreEjecutivo == 0 || venta.nombrePlan == 0 || venta.montoPlan == "" || venta.copago == 0 || venta.rutPagador == ""
                || venta.primerPago == 0 || venta.formaPago == 0 || venta.frecuencia == 0 || venta.iva == 0 || venta.empFacturacion == 0
                || venta.formaFacturacion == 0 || venta.tipoVenta == 0 || venta.formaVenta == 0 || venta.montoPagar == 0) {

                Swal.fire(
                    'Error!',
                    'Debe llenar todos los campos obligatorios.',
                    'warning'
                )
            }
            else {

                if (!Fn.validaRut(venta.rut)) {
                    Swal.fire(
                        'Error!',
                        'Rut ingresado no es válido.',
                        'warning'
                    )
                }
                else if (!Fn.validaRut(venta.rutPagador)) {

                    Swal.fire(
                        'Error!',
                        'Rut pagador ingresado no es válido.',
                        'warning'
                    )
                }
                else if (!validarEmail(venta.correo)) {
                    Swal.fire(
                        'Error!',
                        'Formato correo inválido.',
                        'warning'
                    )
                }
                else if (venta.copago == "SI" && venta.valorCopago == 0)
                {
                    Swal.fire(
                        'Error!',
                        'Es necesario agregar el valor del copago.',
                        'warning'
                    )
                }
                else if (venta.tipoVenta == "Prepago" && venta.cantMesesPrepago == 0) {
                    Swal.fire(
                        'Error!',
                        'Es necesario agregar la cantidad de meses prepago.',
                        'warning'
                    )
                }
                else {
                    response = await fetch("api/contrato/GuardarVenta", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(venta)
                    })


                    if (response.ok) {

                        setVenta(modeloVenta)
                        setVerModal(!verModal);
                        obtenerVentasPendientes();

                        Swal.fire(
                            'Creada!',
                            'Venta Creada.',
                            'success'
                        )

                    } else {
                        Swal.fire(

                            'Error!',
                            'Error al crear venta',
                            'warning'
                        )
                    }
                }
            }
        }
        else {

            if (venta.rut == "" || venta.primerNombre == "" || venta.apellidoPaterno == "" || venta.telefono == "" || venta.correo == ""
                || venta.region == 0 || venta.ciudad == 0 || venta.domicilio == "" || venta.segmento == 0 || venta.rubro == 0
                || venta.nombreEmpresa == 0 || venta.tipoServicio == 0 || venta.canalVenta == 0 || venta.tipoEjecutivo == 0 ||
                venta.nombreEjecutivo == 0 || venta.nombrePlan == 0 || venta.montoPlan == "" || venta.copago == 0 || venta.rutPagador == ""
                || venta.primerPago == 0 || venta.formaPago == 0 || venta.frecuencia == 0 || venta.iva == 0 || venta.empFacturacion == 0
                || venta.formaFacturacion == 0 || venta.tipoVenta == 0 || venta.formaVenta == 0 || venta.montoPagar == 0) {

                Swal.fire(
                    'Error!',
                    'Debe llenar todos los campos obligatorios.',
                    'warning'
                )
            }
            else {

                if (!Fn.validaRut(venta.rut)) {
                    Swal.fire(
                        'Error!',
                        'Rut ingresado no es válido.',
                        'warning'
                    )
                }
                else if (!Fn.validaRut(venta.rutPagador)) {

                    Swal.fire(
                        'Error!',
                        'Rut pagador ingresado no es válido.',
                        'warning'
                    )
                }
                else if (!validarEmail(venta.correo)) {
                    Swal.fire(
                        'Error!',
                        'Formato correo inválido.',
                        'warning'
                    )
                }
                else if (venta.copago == "SI" && venta.valorCopago == 0) {
                    Swal.fire(
                        'Error!',
                        'Es necesario agregar el valor del copago.',
                        'warning'
                    )
                }
                else if (venta.tipoVenta == "Prepago" && venta.cantMesesPrepago == 0) {
                    Swal.fire(
                        'Error!',
                        'Es necesario agregar la cantidad de meses prepago.',
                        'warning'
                    )
                }
                else {
                    response = await fetch("api/contrato/ActualizarVenta", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(venta)
                    })


                    if (response.ok) {

                        setVenta(modeloVenta)
                        setVerModal(!verModal);
                        obtenerVentasPendientes();

                        Swal.fire(
                            'Actualizada!',
                            'Venta Actualizada.',
                            'success'
                        )

                    } else {

                        Swal.fire(
                            'Error!',
                            'Error al actualizar venta',
                            'warning'
                        )
                    }
                }
            }
        }

        

        
    }

    const eliminarVenta = async (idRegistro) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar la venta",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/contrato/eliminarVenta/" + idRegistro, { method: "POST" })
                    .then(response => {
                        if (response.ok) {

                            obtenerVentasPendientes();

                            Swal.fire(
                                'Eliminado!',
                                'La venta fue eliminada.',
                                'success'
                            )
                        }
                    })
            }
        })
    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Ventas Nuevas
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={abrirModal}>Nueva Venta</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={ventas}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        noDataComponent="No se encontraron resultados" //or your component
                    />
                </CardBody>
            </Card>

            <Modal size="xl" isOpen={verModal}>
                <ModalHeader>
                    Ingreso Venta
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={2}>
                            <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Venta :</Label>
                            <DatePicker
                                className="form-control form-control-sm"
                                selected={fechaInicio}
                                onChange={(date) => setFechaInicio(date)}
                                dateFormat='dd/MM/yyyy'
                            />
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="rut" onChange={handleChange} value={venta.rut} id="rut" />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Primer Nombre :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="primerNombre" onChange={handleChange} value={venta.primerNombre} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Segundo Nombre :</Label>
                                <Input bsSize="sm" name="segundoNombre" onChange={handleChange} value={venta.segundoNombre} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido Paterno :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="apellidoPaterno" onChange={handleChange} value={venta.apellidoPaterno} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Apellido Materno :</Label>
                                <Input bsSize="sm" name="apellidoMaterno" onChange={handleChange} value={venta.apellidoMaterno} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Teléfono :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="telefono" onChange={handleChange} value={venta.telefono} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Correo :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="correo" onChange={handleChange} value={venta.correo} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Región :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="region" onChange={handleChange} value={venta.region}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Región de Antofagasta"}>Región de Antofagasta</option>
                                    <option value={"Región de Atacama"}>Región de Atacama</option>
                                    <option value={"Región de Coquimbo"}>Región de Coquimbo</option>
                                    <option value={"Región Metropolitana"}>Región Metropolitana</option>
                                    <option value={"Región de Valparaíso"}>Región de Valparaíso</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Ciudad :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="ciudad" onChange={handleChange} value={venta.ciudad}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Iquique"}>Iquique</option>
                                    <option value={"Antofagasta"}>Antofagasta</option>
                                    <option value={"Calama"}>Calama</option>
                                    <option value={"Copiapó"}>Copiapó</option>
                                    <option value={"Coquimbo"}>Coquimbo</option>
                                    <option value={"La Serena"}>La Serena</option>
                                    <option value={"Santiago"}>Santiago</option>
                                    <option value={"Viña del mar"}>Viña del mar</option>
                                    <option value={"Quilpue"}>Quilpue</option>
                                    
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Domicilio :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="domicilio" onChange={handleChange} value={venta.domicilio} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Segmento :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="segmento" onChange={handleChange} value={venta.segmento}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"PER_persona"}>PER_persona</option>
                                    <option value={"EMP_empresa"}>EMP_empresa</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rubro :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="rubro" onChange={handleChange} value={venta.rubro}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"persona"}>Persona</option>
                                    <option value={"empresa"}>Empresa</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Empresa :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="nombreEmpresa" onChange={handleChange} value={venta.nombreEmpresa} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        planes.map((item) => (<option key={item.idPlan} value={item.nombrePlan}>{item.nombrePlan}</option>))
                                    }
                                    {/*<option value={0}>Seleccionar</option>*/}
                                    {/*<option value={'ACC 2019 PAC VIP'}>ACC 2019 PAC VIP</option>*/}
                                    {/*<option value={'ACC 2019 PAT VIP'}>ACC 2019 PAT VIP</option>*/}
                                    {/*<option value={'ACC 2019 TRAN VIP'}>ACC 2019 TRAN VIP</option>*/}
                                    {/*<option value={'Aguas Antofagasta vip'}>Aguas Antofagasta vip</option>*/}
                                    {/*<option value={'Aguas Chañar Con Copago'}>Aguas Chañar Con Copago</option>*/}
                                    {/*<option value={'Aguas Chañar VIP'}>Aguas Chañar VIP</option>*/}
                                    {/*<option value={'Agusto Dalmar J. Vecinos Afinity'}>Agusto Dalmar J. Vecinos Afinity</option>*/}
                                    {/*<option value={'AK Drilling Con Copago'}>AK Drilling Con Copago</option>*/}
                                    {/*<option value={'AK Drilling Vip'}>AK Drilling Vip</option>*/}
                                    {/*<option value={'Antucoya Con copago'}>Antucoya Con copago</option>*/}
                                    {/*<option value={'Antucoya Vip'}>Antucoya Vip</option>*/}
                                    {/*<option value={'AQC VIP'}>AQC VIP</option>*/}
                                    {/*<option value={'Area Protegida'}>Area Protegida</option>*/}
                                    {/*<option value={'Asociacion Industriales Con Copago'}>Asociacion Industriales Con Copago</option>*/}
                                    {/*<option value={'Asociacion Industriales VIP'}>Asociacion Industriales VIP</option>*/}
                                    {/*<option value={'AUTOWEB'}>AUTOWEB</option>*/}
                                    {/*<option value={'Baptis College Abono fijo'}>Baptis College Abono fijo</option>*/}
                                    {/*<option value={'Broadspectrum Con Copago'}>Broadspectrum Con Copago</option>*/}
                                    {/*<option value={'Broadspectrum VIP'}>Broadspectrum VIP</option>*/}
                                    {/*<option value={'CALAMA PLATINIUM (planes antiguos)'}>CALAMA PLATINIUM (planes antiguos)</option>*/}
                                    {/*<option value={'Candelaria Con Copago'}>Candelaria Con Copago</option>*/}
                                    {/*<option value={'Candelaria Extrangeros Con Copago'}>Candelaria Extrangeros Con Copago</option>*/}
                                    {/*<option value={'Candelaria Extrangeros VIP'}>Candelaria Extrangeros VIP</option>*/}
                                    {/*<option value={'Candelaria VIP'}>Candelaria VIP</option>*/}
                                    {/*<option value={'Carmona y Cia Con Copago'}>Carmona y Cia Con Copago</option>*/}
                                    {/*<option value={'Carmona y Cia VIP'}>Carmona y Cia VIP</option>*/}
                                    {/*<option value={'Casino Marina Con Copago'}>Casino Marina Con Copago</option>*/}
                                    {/*<option value={'Casino Marina VIP'}>Casino Marina VIP</option>*/}
                                    {/*<option value={'CCU'}>CCU</option>*/}
                                    {/*<option value={'Centinela Con Copago'}>Centinela Con Copago</option>*/}
                                    {/*<option value={'Centinela VIP'}>Centinela VIP</option>*/}
                                    {/*<option value={'Cervecerias Unidas Afinnity'}>Cervecerias Unidas Afinnity</option>*/}
                                    {/*<option value={'Clubes Deportivos Afinity'}>Clubes Deportivos Afinity</option>*/}
                                    {/*<option value={'CMDS Antiguo'}>CMDS Antiguo</option>*/}
                                    {/*<option value={'CMDS Vip'}>CMDS Vip</option>*/}
                                    {/*<option value={'Colegio Antofagasta Con Copago'}>Colegio Antofagasta Con Copago</option>*/}
                                    {/*<option value={'Colegio Antofagasta Vip'}>Colegio Antofagasta Vip</option>*/}
                                    {/*<option value={'Colegio Antonio Rendic Premium'}>Colegio Antonio Rendic Premium</option>*/}
                                    {/*<option value={'Colegio Antonio Rendic proteccion'}>Colegio Antonio Rendic proteccion</option>*/}
                                    {/*<option value={'Colegio Betel Convenio'}>Colegio Betel Convenio</option>*/}
                                    {/*<option value={'Colegio Chañares'}>Colegio Chañares</option>*/}
                                    {/*<option value={'Colegios Generico'}>Colegios Generico</option>*/}
                                    {/*<option value={'Collahuasi VIP'}>Collahuasi VIP</option>*/}
                                    {/*<option value={'Coloso'}>Coloso</option>*/}
                                    {/*<option value={'Comdes Con Copago'}>Comdes Con Copago</option>*/}
                                    {/*<option value={'Comdes VIP'}>Comdes VIP</option>*/}
                                    {/*<option value={'Convenio Empresa Generico'}>Convenio Empresa Generico</option>*/}
                                    {/*<option value={'Convenio Funcionarios Generico'}>Convenio Funcionarios Generico</option>*/}
                                    {/*<option value={'Corfo Afinity'}>Corfo Afinity</option>*/}
                                    {/*<option value={'Cruz Blanca Con Copago Afinity'}>Cruz Blanca Con Copago Afinity</option>*/}
                                    {/*<option value={'Cruz Blanca VIP Afinity'}>Cruz Blanca VIP Afinity</option>*/}
                                    {/*<option value={'CYBER-21 ANTOF.COP.FULLCOBERTURA'}>CYBER-21 ANTOF.COP.FULLCOBERTURA</option>*/}
                                    {/*<option value={'CYBER-21 LS.CA FULL COBERTURA'}>CYBER-21 LS.CA FULL COBERTURA</option>*/}
                                    {/*<option value={'Ejercito de Chile Convenio'}>Ejercito de Chile Convenio</option>*/}
                                    {/*<option value={'Escondida Sindicato'}>Escondida Sindicato</option>*/}
                                    {/*<option value={'Evento Protegido'}>Evento Protegido</option>*/}
                                    {/*<option value={'Felicity 360 Con Copago'}>Felicity 360 Con Copago</option>*/}
                                    {/*<option value={'Felicity 360 VIP'}>Felicity 360 VIP</option>*/}
                                    {/*<option value={'Ferreteria Prat'}>Ferreteria Prat</option>*/}
                                    {/*<option value={'Ferrocarril'}>Ferrocarril</option>*/}
                                    {/*<option value={'Ferrocarril Extrangeros'}>Ferrocarril Extrangeros</option>*/}
                                    {/*<option value={'FESUMIN'}>FESUMIN</option>*/}
                                    {/*<option value={'Finning Con Copago'}>Finning Con Copago</option>*/}
                                    {/*<option value={'Finning VIP'}>Finning VIP</option>*/}
                                    {/*<option value={'Fundacion Cervera Abono Fijo'}>Fundacion Cervera Abono Fijo</option>*/}
                                    {/*<option value={'Fundacion Cervera Antiguo'}>Fundacion Cervera Antiguo</option>*/}
                                    {/*<option value={'Gendarmeria Con Copago'}>Gendarmeria Con Copago</option>*/}
                                    {/*<option value={'Gendarmeria VIP'}>Gendarmeria VIP</option>*/}
                                    {/*<option value={'Generico jardines'}>Generico jardines</option>*/}
                                    {/*<option value={'Generico mineras'}>Generico mineras</option>*/}
                                    {/*<option value={'Gesta Mineria Con Copago'}>Gesta Mineria Con Copago</option>*/}
                                    {/*<option value={'Gesta Mineria VIP'}>Gesta Mineria VIP</option>*/}
                                    {/*<option value={'GORE Con Copago'}>GORE Con Copago</option>*/}
                                    {/*<option value={'GORE VIP'}>GORE VIP</option>*/}
                                    {/*<option value={'Gran Via J. Vecinos Afinity'}>Gran Via J. Vecinos Afinity</option>*/}
                                    {/*<option value={'Guanaco Con Copago'}>Guanaco Con Copago</option>*/}
                                    {/*<option value={'Guanaco VIP'}>Guanaco VIP</option>*/}
                                    {/*<option value={'GUIÑEZ INGENIERIA Con Copago'}>GUIÑEZ INGENIERIA Con Copago</option>*/}
                                    {/*<option value={'GUIÑEZ INGENIERIA VIP'}>GUIÑEZ INGENIERIA VIP</option>*/}
                                    {/*<option value={'Harvesr Con Copago'}>Harvesr Con Copago</option>*/}
                                    {/*<option value={'Harvest VIP'}>Harvest VIP</option>*/}
                                    {/*<option value={'Iglesia Afinity'}>Iglesia Afinity</option>*/}
                                    {/*<option value={'Itau Afinity'}>Itau Afinity</option>*/}
                                    {/*<option value={'Jardin Papeucho'}>Jardin Papeucho</option>*/}
                                    {/*<option value={'Jardines General Afinity'}>Jardines General Afinity</option>*/}
                                    {/*<option value={'Jardines Junji Afinity'}>Jardines Junji Afinity</option>*/}
                                    {/*<option value={'Junta de vecinos Afinity'}>Junta de vecinos Afinity</option>*/}
                                    {/*<option value={'Lancaster Convenio'}>Lancaster Convenio</option>*/}
                                    {/*<option value={'LEA Convenio'}>LEA Convenio</option>*/}
                                    {/*<option value={'Lecya Convenio'}>Lecya Convenio</option>*/}
                                    {/*<option value={'Linsa Tramaca VIP'}>Linsa Tramaca VIP</option>*/}
                                    {/*<option value={'Lomas Bayas'}>Lomas Bayas</option>*/}
                                    {/*<option value={'LSR 2019 PAC'}>LSR 2019 PAC</option>*/}
                                    {/*<option value={'LSR 2019 PAC VIP'}>LSR 2019 PAC VIP</option>*/}
                                    {/*<option value={'LSR 2019 PAT VIP'}>LSR 2019 PAT VIP</option>*/}
                                    {/*<option value={'LSR 2019 TRAN VIP'}>LSR 2019 TRAN VIP</option>*/}
                                    {/*<option value={'Madre Del Rosario Convenio'}>Madre Del Rosario Convenio</option>*/}
                                    {/*<option value={'MEDIFAM VIP'}>MEDIFAM VIP</option>*/}
                                    {/*<option value={'MEDIMEL'}>MEDIMEL</option>*/}
                                    {/*<option value={'MOP'}>MOP</option>*/}
                                    {/*<option value={'MOP Convenio'}>MOP Convenio</option>*/}
                                    {/*<option value={'Municipalidad Antofagasta Bienestar'}>Municipalidad Antofagasta Bienestar</option>*/}
                                    {/*<option value={'Municipalidad Antofagasta Honorarios'}>Municipalidad Antofagasta Honorarios</option>*/}
                                    {/*<option value={'Mutual Con Copago Afinity'}>Mutual Con Copago Afinity</option>*/}
                                    {/*<option value={'Mutual VIP Afinity'}>Mutual VIP Afinity</option>*/}
                                    {/*<option value={'Netland School Con Copago'}>Netland School Con Copago</option>*/}
                                    {/*<option value={'Netland School VIP'}>Netland School VIP</option>*/}
                                    {/*<option value={'Nev Heaven Convenio'}>Nev Heaven Convenio</option>*/}
                                    {/*<option value={'Nittetsu'}>Nittetsu</option>*/}
                                    {/*<option value={'PAD REST911 2020-A'}>PAD REST911 2020-A</option>*/}
                                    {/*<option value={'PAD REST911 2020-B'}>PAD REST911 2020-B</option>*/}
                                    {/*<option value={'PAD REST911 2020-C'}>PAD REST911 2020-C</option>*/}
                                    {/*<option value={'Papelucho Jardin Convenio'}>Papelucho Jardin Convenio</option>*/}
                                    {/*<option value={'Parvulito Jardin Convenio'}>Parvulito Jardin Convenio</option>*/}
                                    {/*<option value={'PAT TARJETA CREDITO 12 MESES-A'}>PAT TARJETA CREDITO 12 MESES-A</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 6 MESES B'}>PAT TARJETA DE CREDITO 6 MESES B</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 12 MESE-C'}>PAT TARJETA DE CREDITO 12 MESE-C</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 2020-A'}>PAT TARJETA DE CREDITO 2020-A</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 2020-B'}>PAT TARJETA DE CREDITO 2020-B</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 2020-C'}>PAT TARJETA DE CREDITO 2020-C</option>*/}
                                    {/*<option value={'PAT TARJETA DE CREDITO 6 MESES-C'}>PAT TARJETA DE CREDITO 6 MESES-C</option>*/}
                                    {/*<option value={'Persona Generico'}>Persona Generico</option>*/}
                                    {/*<option value={'plan descuento planilla 7x16.000'}>plan descuento planilla 7x16.000</option>*/}
                                    {/*<option value={'Planes afinity generico'}>Planes afinity generico</option>*/}
                                    {/*<option value={'PLATINIUM CON COPAGO'}>PLATINIUM CON COPAGO</option>*/}
                                    {/*<option value={'PLATINIUM PAGO DIRECTO IV REGION'}>PLATINIUM PAGO DIRECTO IV REGION</option>*/}
                                    {/*<option value={'PLATINIUM VIP'}>PLATINIUM VIP</option>*/}
                                    {/*<option value={'Prebisteriano APR'}>Prebisteriano APR</option>*/}
                                    {/*<option value={'Prebisteriano Convenio'}>Prebisteriano Convenio</option>*/}
                                    {/*<option value={'Pucobre Domiciliarias'}>Pucobre Domiciliarias</option>*/}
                                    {/*<option value={'Puerto Ati'}>Puerto Ati</option>*/}
                                    {/*<option value={'Remarketing 2019 VIP'}>Remarketing 2019 VIP</option>*/}
                                    {/*<option value={'Republica de Grecia Convenio'}>Republica de Grecia Convenio</option>*/}
                                    {/*<option value={'REST911 Funcionarios'}>REST911 Funcionarios</option>*/}
                                    {/*<option value={'Santa Emilia Convenio'}>Santa Emilia Convenio</option>*/}
                                    {/*<option value={'SINDICARO SIERRA GORDA'}>SINDICARO SIERRA GORDA</option>*/}
                                    {/*<option value={'Sindicato Compañía Cnetinela'}>Sindicato Compañía Cnetinela</option>*/}
                                    {/*<option value={'Sindicato DMH'}>Sindicato DMH</option>*/}
                                    {/*<option value={'Sindicato El Abra'}>Sindicato El Abra</option>*/}
                                    {/*<option value={'Sindicato Mina El Abra'}>Sindicato Mina El Abra</option>*/}
                                    {/*<option value={'SINDICATO NRO 3 MANTOS BLANCOS'}>SINDICATO NRO 3 MANTOS BLANCOS</option>*/}
                                    {/*<option value={'Sur Asistencia Club Beneficios'}>Sur Asistencia Club Beneficios</option>*/}
                                    {/*<option value={'Tecnologias Cobra Con Copago'}>Tecnologias Cobra Con Copago</option>*/}
                                    {/*<option value={'Tecnologias Cobra VIP'}>Tecnologias Cobra VIP</option>*/}
                                    {/*<option value={'TRANS 2020 TRAMO A'}>TRANS 2020 TRAMO A</option>*/}
                                    {/*<option value={'TRANS 2020 TRAMO B'}>TRANS 2020 TRAMO B</option>*/}
                                    {/*<option value={'UDA con copago'}>UDA con copago</option>*/}
                                    {/*<option value={'Uda vip'}>Uda vip</option>*/}
                                    {/*<option value={'Universidad Antofagasta Afinity'}>Universidad Antofagasta Afinity</option>*/}
                                    {/*<option value={'Universidad catolica del norte vip'}>Universidad catolica del norte vip</option>*/}
                                    {/*<option value={'VENTA REMOTA VIP'}>VENTA REMOTA VIP</option>*/}
                                    {/*<option value={'WEB PAGO 12M CON COPAGO'}>WEB PAGO 12M CON COPAGO</option>*/}
                                    {/*<option value={'WEB PAGO 12M VIP'}>WEB PAGO 12M VIP</option>*/}
                                    {/*<option value={'WEB PAGO 1M CON COPAGO'}>WEB PAGO 1M CON COPAGO</option>*/}
                                    {/*<option value={'WEB PAGO 1M VIP'}>WEB PAGO 1M VIP</option>*/}
                                    {/*<option value={'WEB PAGO 3M CON COPAGO'}>WEB PAGO 3M CON COPAGO</option>*/}
                                    {/*<option value={'WEB PAGO 3M VIP'}>WEB PAGO 3M VIP</option>*/}
                                    {/*<option value={'WEB PAGO 6M CON COPAGO'}>WEB PAGO 6M CON COPAGO</option>*/}
                                    {/*<option value={'WEB PAGO 6M VIP'}>WEB PAGO 6M VIP</option>*/}
                                    {/*<option value={'PLAN COBRE'}>PLAN COBRE</option>*/}
                                    {/*<option value={'PLAN BRONCE'}>PLAN BRONCE</option>*/}
                                    {/*<option value={'C Y M COMERCIAL VIP'}>C Y M COMERCIAL VIP</option>*/}
                                    {/*<option value={'C Y M COMERCIAL COPAGO'}>C Y M COMERCIAL COPAGO</option>*/}
                                    {/*<option value={'COLEGIO SAN AGUSTIN'}>COLEGIO SAN AGUSTIN</option>*/}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Tipo Servicio :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="tipoServicio" onChange={handleChange} value={venta.tipoServicio}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"PFM_Plan_Familia"}>PFM_Plan_Familia</option>
                                    <option value={"CON_Convenio_Empresa"}>CON_Convenio_Empresa</option>
                                    <option value={"APR_Area_Protegida"}>APR_Area_Protegida</option>
                                    <option value={"ASE_Aseguradoras"}>ASE_Aseguradoras</option>
                                    <option value={"TEST_Test_Covid"}>TEST_Test_Covid</option>
                                    <option value={"TRS_Traslados"}>TRS_Traslados</option>
                                    <option value={"EVE_Evento_Protegido"}>EVE_Evento_Protegido</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Canal Venta :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="canalVenta" onChange={handleChange} value={venta.canalVenta}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"REMOTA"}>REMOTA</option>
                                    <option value={"PRESENCIAL"}>PRESENCIAL</option>
                                    <option value={"AUTOWEB"}>AUTOWEB</option>
                                    <option value={"GERENCIA"}>GERENCIA</option>
                                    <option value={"DEALER"}>DEALER</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Tipo Ejecutivo :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="tipoEjecutivo" onChange={handleChange} value={venta.tipoEjecutivo}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"AUTOWEB"}>AUTOWEB</option>
                                    <option value={"FREELANCE"}>FREELANCE</option>
                                    <option value={"VTA.INTERNA"}>VTA.INTERNA</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            {/*<FormGroup>*/}
                            {/*    <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Ejecutivo :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>*/}
                            {/*    <Input bsSize="sm" type={"select"} name="nombreEjecutivo" onChange={handleChange} value={venta.nombreEjecutivo}>*/}
                            {/*        <option value={0}>Seleccionar</option>*/}
                            {/*        <option value={'Autoweb'}>Autoweb</option>*/}
                            {/*        <option value={'Andrea Casas'}>Andrea Casas</option>*/}
                            {/*        <option value={'Barbara Morales'}>Barbara Morales</option>*/}
                            {/*        <option value={'Milagros Morales'}>Milagros Morales</option>*/}
                            {/*        <option value={'Katherine Tobar'}>Katherine Tobar</option>*/}
                            {/*        <option value={'Leyla Guzman'}>Leyla Guzman</option>*/}
                            {/*        <option value={'Miriam Muñoz'}>Miriam Muñoz</option>*/}
                            {/*        <option value={'Miriam Rivera'}>Miriam Rivera</option>*/}
                            {/*        <option value={'Paola Alzamora'}>Paola Alzamora</option>*/}
                            {/*        <option value={'Veronica Lanas'}>Veronica Lanas</option>*/}
                            {/*        <option value={'Gerencia'}>Gerencia</option>*/}
                            {/*        <option value={'SAC-Mantecion'}>SAC-Mantecion</option>*/}

                            {/*    </Input>*/}
                            {/*</FormGroup>*/}
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Ejecutivo :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="nombreEjecutivo" onChange={handleChange} value={venta.nombreEjecutivo} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        vendedores.map((item) => (<option key={item.nombreVendedor} value={item.nombreVendedor}>{item.nombreVendedor}</option>))
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Plan :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="nombrePlan" onChange={handleChange} value={venta.nombrePlan} >
                                    <option value={0}>Seleccionar</option>
                                    {
                                        planes.map((item) => (<option key={item.idPlan} value={item.nombrePlan}>{item.nombrePlan}</option>))
                                    }
                                </Input>
                            </FormGroup>
                            {/*<FormGroup>*/}
                            {/*    <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Plan :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>*/}
                            {/*    <Input bsSize="sm" type={"select"} name="nombrePlan" onChange={handleChange} value={venta.nombrePlan}>*/}
                            {/*        <option value={0}>Seleccionar</option>*/}
                            {/*        <option value={'ACC 2019 PAC VIP'}>ACC 2019 PAC VIP</option>*/}
                            {/*        <option value={'ACC 2019 PAT VIP'}>ACC 2019 PAT VIP</option>*/}
                            {/*        <option value={'ACC 2019 TRAN VIP'}>ACC 2019 TRAN VIP</option>*/}
                            {/*        <option value={'Aguas Antofagasta vip'}>Aguas Antofagasta vip</option>*/}
                            {/*        <option value={'Aguas Chañar Con Copago'}>Aguas Chañar Con Copago</option>*/}
                            {/*        <option value={'Aguas Chañar VIP'}>Aguas Chañar VIP</option>*/}
                            {/*        <option value={'Agusto Dalmar J. Vecinos Afinity'}>Agusto Dalmar J. Vecinos Afinity</option>*/}
                            {/*        <option value={'AK Drilling Con Copago'}>AK Drilling Con Copago</option>*/}
                            {/*        <option value={'AK Drilling Vip'}>AK Drilling Vip</option>*/}
                            {/*        <option value={'Antucoya Con copago'}>Antucoya Con copago</option>*/}
                            {/*        <option value={'Antucoya Vip'}>Antucoya Vip</option>*/}
                            {/*        <option value={'AQC VIP'}>AQC VIP</option>*/}
                            {/*        <option value={'Area Protegida'}>Area Protegida</option>*/}
                            {/*        <option value={'Asociacion Industriales Con Copago'}>Asociacion Industriales Con Copago</option>*/}
                            {/*        <option value={'Asociacion Industriales VIP'}>Asociacion Industriales VIP</option>*/}
                            {/*        <option value={'AUTOWEB'}>AUTOWEB</option>*/}
                            {/*        <option value={'Baptis College Abono fijo'}>Baptis College Abono fijo</option>*/}
                            {/*        <option value={'Broadspectrum Con Copago'}>Broadspectrum Con Copago</option>*/}
                            {/*        <option value={'Broadspectrum VIP'}>Broadspectrum VIP</option>*/}
                            {/*        <option value={'CALAMA PLATINIUM (planes antiguos)'}>CALAMA PLATINIUM (planes antiguos)</option>*/}
                            {/*        <option value={'Candelaria Con Copago'}>Candelaria Con Copago</option>*/}
                            {/*        <option value={'Candelaria Extrangeros Con Copago'}>Candelaria Extrangeros Con Copago</option>*/}
                            {/*        <option value={'Candelaria Extrangeros VIP'}>Candelaria Extrangeros VIP</option>*/}
                            {/*        <option value={'Candelaria VIP'}>Candelaria VIP</option>*/}
                            {/*        <option value={'Carmona y Cia Con Copago'}>Carmona y Cia Con Copago</option>*/}
                            {/*        <option value={'Carmona y Cia VIP'}>Carmona y Cia VIP</option>*/}
                            {/*        <option value={'Casino Marina Con Copago'}>Casino Marina Con Copago</option>*/}
                            {/*        <option value={'Casino Marina VIP'}>Casino Marina VIP</option>*/}
                            {/*        <option value={'CCU'}>CCU</option>*/}
                            {/*        <option value={'Centinela Con Copago'}>Centinela Con Copago</option>*/}
                            {/*        <option value={'Centinela VIP'}>Centinela VIP</option>*/}
                            {/*        <option value={'Cervecerias Unidas Afinnity'}>Cervecerias Unidas Afinnity</option>*/}
                            {/*        <option value={'Clubes Deportivos Afinity'}>Clubes Deportivos Afinity</option>*/}
                            {/*        <option value={'CMDS Antiguo'}>CMDS Antiguo</option>*/}
                            {/*        <option value={'CMDS Vip'}>CMDS Vip</option>*/}
                            {/*        <option value={'Colegio Antofagasta Con Copago'}>Colegio Antofagasta Con Copago</option>*/}
                            {/*        <option value={'Colegio Antofagasta Vip'}>Colegio Antofagasta Vip</option>*/}
                            {/*        <option value={'Colegio Antonio Rendic Premium'}>Colegio Antonio Rendic Premium</option>*/}
                            {/*        <option value={'Colegio Antonio Rendic proteccion'}>Colegio Antonio Rendic proteccion</option>*/}
                            {/*        <option value={'Colegio Betel Convenio'}>Colegio Betel Convenio</option>*/}
                            {/*        <option value={'Colegio Chañares'}>Colegio Chañares</option>*/}
                            {/*        <option value={'Colegios Generico'}>Colegios Generico</option>*/}
                            {/*        <option value={'Collahuasi VIP'}>Collahuasi VIP</option>*/}
                            {/*        <option value={'Coloso'}>Coloso</option>*/}
                            {/*        <option value={'Comdes Con Copago'}>Comdes Con Copago</option>*/}
                            {/*        <option value={'Comdes VIP'}>Comdes VIP</option>*/}
                            {/*        <option value={'Convenio Empresa Generico'}>Convenio Empresa Generico</option>*/}
                            {/*        <option value={'Convenio Funcionarios Generico'}>Convenio Funcionarios Generico</option>*/}
                            {/*        <option value={'Corfo Afinity'}>Corfo Afinity</option>*/}
                            {/*        <option value={'Cruz Blanca Con Copago Afinity'}>Cruz Blanca Con Copago Afinity</option>*/}
                            {/*        <option value={'Cruz Blanca VIP Afinity'}>Cruz Blanca VIP Afinity</option>*/}
                            {/*        <option value={'CYBER-21 ANTOF.COP.FULLCOBERTURA'}>CYBER-21 ANTOF.COP.FULLCOBERTURA</option>*/}
                            {/*        <option value={'CYBER-21 LS.CA FULL COBERTURA'}>CYBER-21 LS.CA FULL COBERTURA</option>*/}
                            {/*        <option value={'Ejercito de Chile Convenio'}>Ejercito de Chile Convenio</option>*/}
                            {/*        <option value={'Escondida Sindicato'}>Escondida Sindicato</option>*/}
                            {/*        <option value={'Evento Protegido'}>Evento Protegido</option>*/}
                            {/*        <option value={'Felicity 360 Con Copago'}>Felicity 360 Con Copago</option>*/}
                            {/*        <option value={'Felicity 360 VIP'}>Felicity 360 VIP</option>*/}
                            {/*        <option value={'Ferreteria Prat'}>Ferreteria Prat</option>*/}
                            {/*        <option value={'Ferrocarril'}>Ferrocarril</option>*/}
                            {/*        <option value={'Ferrocarril Extrangeros'}>Ferrocarril Extrangeros</option>*/}
                            {/*        <option value={'FESUMIN'}>FESUMIN</option>*/}
                            {/*        <option value={'Finning Con Copago'}>Finning Con Copago</option>*/}
                            {/*        <option value={'Finning VIP'}>Finning VIP</option>*/}
                            {/*        <option value={'Fundacion Cervera Abono Fijo'}>Fundacion Cervera Abono Fijo</option>*/}
                            {/*        <option value={'Fundacion Cervera Antiguo'}>Fundacion Cervera Antiguo</option>*/}
                            {/*        <option value={'Gendarmeria Con Copago'}>Gendarmeria Con Copago</option>*/}
                            {/*        <option value={'Gendarmeria VIP'}>Gendarmeria VIP</option>*/}
                            {/*        <option value={'Generico jardines'}>Generico jardines</option>*/}
                            {/*        <option value={'Generico mineras'}>Generico mineras</option>*/}
                            {/*        <option value={'Gesta Mineria Con Copago'}>Gesta Mineria Con Copago</option>*/}
                            {/*        <option value={'Gesta Mineria VIP'}>Gesta Mineria VIP</option>*/}
                            {/*        <option value={'GORE Con Copago'}>GORE Con Copago</option>*/}
                            {/*        <option value={'GORE VIP'}>GORE VIP</option>*/}
                            {/*        <option value={'Gran Via J. Vecinos Afinity'}>Gran Via J. Vecinos Afinity</option>*/}
                            {/*        <option value={'Guanaco Con Copago'}>Guanaco Con Copago</option>*/}
                            {/*        <option value={'Guanaco VIP'}>Guanaco VIP</option>*/}
                            {/*        <option value={'GUIÑEZ INGENIERIA Con Copago'}>GUIÑEZ INGENIERIA Con Copago</option>*/}
                            {/*        <option value={'GUIÑEZ INGENIERIA VIP'}>GUIÑEZ INGENIERIA VIP</option>*/}
                            {/*        <option value={'Harvesr Con Copago'}>Harvesr Con Copago</option>*/}
                            {/*        <option value={'Harvest VIP'}>Harvest VIP</option>*/}
                            {/*        <option value={'Iglesia Afinity'}>Iglesia Afinity</option>*/}
                            {/*        <option value={'Itau Afinity'}>Itau Afinity</option>*/}
                            {/*        <option value={'Jardin Papeucho'}>Jardin Papeucho</option>*/}
                            {/*        <option value={'Jardines General Afinity'}>Jardines General Afinity</option>*/}
                            {/*        <option value={'Jardines Junji Afinity'}>Jardines Junji Afinity</option>*/}
                            {/*        <option value={'Junta de vecinos Afinity'}>Junta de vecinos Afinity</option>*/}
                            {/*        <option value={'Lancaster Convenio'}>Lancaster Convenio</option>*/}
                            {/*        <option value={'LEA Convenio'}>LEA Convenio</option>*/}
                            {/*        <option value={'Lecya Convenio'}>Lecya Convenio</option>*/}
                            {/*        <option value={'Linsa Tramaca VIP'}>Linsa Tramaca VIP</option>*/}
                            {/*        <option value={'Lomas Bayas'}>Lomas Bayas</option>*/}
                            {/*        <option value={'LSR 2019 PAC'}>LSR 2019 PAC</option>*/}
                            {/*        <option value={'LSR 2019 PAC VIP'}>LSR 2019 PAC VIP</option>*/}
                            {/*        <option value={'LSR 2019 PAT VIP'}>LSR 2019 PAT VIP</option>*/}
                            {/*        <option value={'LSR 2019 TRAN VIP'}>LSR 2019 TRAN VIP</option>*/}
                            {/*        <option value={'Madre Del Rosario Convenio'}>Madre Del Rosario Convenio</option>*/}
                            {/*        <option value={'MEDIFAM VIP'}>MEDIFAM VIP</option>*/}
                            {/*        <option value={'MEDIMEL'}>MEDIMEL</option>*/}
                            {/*        <option value={'MOP'}>MOP</option>*/}
                            {/*        <option value={'MOP Convenio'}>MOP Convenio</option>*/}
                            {/*        <option value={'Municipalidad Antofagasta Bienestar'}>Municipalidad Antofagasta Bienestar</option>*/}
                            {/*        <option value={'Municipalidad Antofagasta Honorarios'}>Municipalidad Antofagasta Honorarios</option>*/}
                            {/*        <option value={'Mutual Con Copago Afinity'}>Mutual Con Copago Afinity</option>*/}
                            {/*        <option value={'Mutual VIP Afinity'}>Mutual VIP Afinity</option>*/}
                            {/*        <option value={'Netland School Con Copago'}>Netland School Con Copago</option>*/}
                            {/*        <option value={'Netland School VIP'}>Netland School VIP</option>*/}
                            {/*        <option value={'Nev Heaven Convenio'}>Nev Heaven Convenio</option>*/}
                            {/*        <option value={'Nittetsu'}>Nittetsu</option>*/}
                            {/*        <option value={'PAD REST911 2020-A'}>PAD REST911 2020-A</option>*/}
                            {/*        <option value={'PAD REST911 2020-B'}>PAD REST911 2020-B</option>*/}
                            {/*        <option value={'PAD REST911 2020-C'}>PAD REST911 2020-C</option>*/}
                            {/*        <option value={'Papelucho Jardin Convenio'}>Papelucho Jardin Convenio</option>*/}
                            {/*        <option value={'Parvulito Jardin Convenio'}>Parvulito Jardin Convenio</option>*/}
                            {/*        <option value={'PAT TARJETA CREDITO 12 MESES-A'}>PAT TARJETA CREDITO 12 MESES-A</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 6 MESES B'}>PAT TARJETA DE CREDITO 6 MESES B</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 12 MESE-C'}>PAT TARJETA DE CREDITO 12 MESE-C</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 2020-A'}>PAT TARJETA DE CREDITO 2020-A</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 2020-B'}>PAT TARJETA DE CREDITO 2020-B</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 2020-C'}>PAT TARJETA DE CREDITO 2020-C</option>*/}
                            {/*        <option value={'PAT TARJETA DE CREDITO 6 MESES-C'}>PAT TARJETA DE CREDITO 6 MESES-C</option>*/}
                            {/*        <option value={'Persona Generico'}>Persona Generico</option>*/}
                            {/*        <option value={'plan descuento planilla 7x16.000'}>plan descuento planilla 7x16.000</option>*/}
                            {/*        <option value={'Planes afinity generico'}>Planes afinity generico</option>*/}
                            {/*        <option value={'PLATINIUM CON COPAGO'}>PLATINIUM CON COPAGO</option>*/}
                            {/*        <option value={'PLATINIUM PAGO DIRECTO IV REGION'}>PLATINIUM PAGO DIRECTO IV REGION</option>*/}
                            {/*        <option value={'PLATINIUM VIP'}>PLATINIUM VIP</option>*/}
                            {/*        <option value={'Prebisteriano APR'}>Prebisteriano APR</option>*/}
                            {/*        <option value={'Prebisteriano Convenio'}>Prebisteriano Convenio</option>*/}
                            {/*        <option value={'Pucobre Domiciliarias'}>Pucobre Domiciliarias</option>*/}
                            {/*        <option value={'Puerto Ati'}>Puerto Ati</option>*/}
                            {/*        <option value={'Remarketing 2019 VIP'}>Remarketing 2019 VIP</option>*/}
                            {/*        <option value={'Republica de Grecia Convenio'}>Republica de Grecia Convenio</option>*/}
                            {/*        <option value={'REST911 Funcionarios'}>REST911 Funcionarios</option>*/}
                            {/*        <option value={'Santa Emilia Convenio'}>Santa Emilia Convenio</option>*/}
                            {/*        <option value={'SINDICARO SIERRA GORDA'}>SINDICARO SIERRA GORDA</option>*/}
                            {/*        <option value={'Sindicato Compañía Cnetinela'}>Sindicato Compañía Cnetinela</option>*/}
                            {/*        <option value={'Sindicato DMH'}>Sindicato DMH</option>*/}
                            {/*        <option value={'Sindicato El Abra'}>Sindicato El Abra</option>*/}
                            {/*        <option value={'Sindicato Mina El Abra'}>Sindicato Mina El Abra</option>*/}
                            {/*        <option value={'SINDICATO NRO 3 MANTOS BLANCOS'}>SINDICATO NRO 3 MANTOS BLANCOS</option>*/}
                            {/*        <option value={'Sur Asistencia Club Beneficios'}>Sur Asistencia Club Beneficios</option>*/}
                            {/*        <option value={'Tecnologias Cobra Con Copago'}>Tecnologias Cobra Con Copago</option>*/}
                            {/*        <option value={'Tecnologias Cobra VIP'}>Tecnologias Cobra VIP</option>*/}
                            {/*        <option value={'TRANS 2020 TRAMO A'}>TRANS 2020 TRAMO A</option>*/}
                            {/*        <option value={'TRANS 2020 TRAMO B'}>TRANS 2020 TRAMO B</option>*/}
                            {/*        <option value={'UDA con copago'}>UDA con copago</option>*/}
                            {/*        <option value={'Uda vip'}>Uda vip</option>*/}
                            {/*        <option value={'Universidad Antofagasta Afinity'}>Universidad Antofagasta Afinity</option>*/}
                            {/*        <option value={'Universidad catolica del norte vip'}>Universidad catolica del norte vip</option>*/}
                            {/*        <option value={'VENTA REMOTA VIP'}>VENTA REMOTA VIP</option>*/}
                            {/*        <option value={'WEB PAGO 12M CON COPAGO'}>WEB PAGO 12M CON COPAGO</option>*/}
                            {/*        <option value={'WEB PAGO 12M VIP'}>WEB PAGO 12M VIP</option>*/}
                            {/*        <option value={'WEB PAGO 1M CON COPAGO'}>WEB PAGO 1M CON COPAGO</option>*/}
                            {/*        <option value={'WEB PAGO 1M VIP'}>WEB PAGO 1M VIP</option>*/}
                            {/*        <option value={'WEB PAGO 3M CON COPAGO'}>WEB PAGO 3M CON COPAGO</option>*/}
                            {/*        <option value={'WEB PAGO 3M VIP'}>WEB PAGO 3M VIP</option>*/}
                            {/*        <option value={'WEB PAGO 6M CON COPAGO'}>WEB PAGO 6M CON COPAGO</option>*/}
                            {/*        <option value={'WEB PAGO 6M VIP'}>WEB PAGO 6M VIP</option>*/}
                            {/*        <option value={'PLAN COBRE'}>PLAN COBRE</option>*/}
                            {/*        <option value={'PLAN BRONCE'}>PLAN BRONCE</option>*/}
                            {/*        <option value={'C Y M COMERCIAL VIP'}>C Y M COMERCIAL VIP</option>*/}
                            {/*        <option value={'C Y M COMERCIAL COPAGO'}>C Y M COMERCIAL COPAGO</option>*/}
                            {/*        <option value={'COLEGIO SAN AGUSTIN'}>COLEGIO SAN AGUSTIN</option>*/}
                            {/*    </Input>*/}
                            {/*</FormGroup>*/}
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Num Beneficiarios :</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="numBeneficiarios" onChange={handleChange} value={venta.numBeneficiarios} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="montoPlan" onChange={handleChange} value={venta.montoPlan} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Copago :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="copago" onChange={handleChange} value={venta.copago}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"SI"}>SI</option>
                                    <option value={"NO"}>NO</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Valor Copago :</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="valorCopago" onChange={handleChange} value={venta.valorCopago} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut Pagador:</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" name="rutPagador" onChange={handleChange} value={venta.rutPagador} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Primer Pago :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="primerPago" onChange={handleChange} value={venta.primerPago}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"ONE CLICK"}>ONE CLICK</option>
                                    <option value={"TRANSFERENCIA"}>TRANSFERENCIA</option>
                                    <option value={"DESCT PLANILLA"}>DESCT PLANILLA</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Forma Pago :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="formaPago" onChange={handleChange} value={venta.formaPago}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"ONE CLICK"}>ONE CLICK</option>
                                    <option value={"TRANSFERENCIA"}>TRANSFERENCIA</option>
                                    <option value={"DESCT PLANILLA"}>DESCT PLANILLA</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Frecuencia :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="frecuencia" onChange={handleChange} value={venta.frecuencia}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={'Cada 1 mes'}>Cada 1 mes</option>
                                    <option value={'Cada 2 meses'}>Cada 2 meses</option>
                                    <option value={'Cada 3 meses'}>Cada 3 meses</option>
                                    <option value={'Cada 4 meses'}>Cada 4 meses</option>
                                    <option value={'Cada 5 meses'}>Cada 5 meses</option>
                                    <option value={'Cada 6 meses'}>Cada 6 meses</option>
                                    <option value={'Cada 7 meses'}>Cada 7 meses</option>
                                    <option value={'Cada 8 meses'}>Cada 8 meses</option>
                                    <option value={'Cada 9 meses'}>Cada 9 meses</option>
                                    <option value={'Cada 10 meses'}>Cada 10 meses</option>
                                    <option value={'Cada 11 meses'}>Cada 11 meses</option>
                                    <option value={'Cada 12 meses'}>Cada 12 meses</option>
                                    <option value={'Pago unico'}>Pago unico</option>

                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Iva :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="iva" onChange={handleChange} value={venta.iva}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Afecto"}>Afecto</option>
                                    <option value={"Exento"}>Exento</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Emp Facturación :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="empFacturacion" onChange={handleChange} value={venta.empFacturacion}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"RST"}>RST</option>
                                    <option value={"Copayapu"}>Copayapu</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Forma Facturación :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="formaFacturacion" onChange={handleChange} value={venta.formaFacturacion}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Plan"}>Plan</option>
                                    <option value={"Abono Fijo"}>Abono Fijo</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Tipo Venta :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="tipoVenta" onChange={handleChange} value={venta.tipoVenta}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Normal"}>Normal</option>
                                    <option value={"Prepago"}>Prepago</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Cant. Meses Prepago :</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="cantMesesPrepago" onChange={handleChange} value={venta.cantMesesPrepago} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">% Descuento :</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="descuento" onChange={handleChange} value={venta.descuento} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto a Pagar :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input type="number" style={{ fontSize: "12px" }} name="montoPagar" onChange={handleChange} value={venta.montoPagar} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Forma Venta :</Label> <Label style={{ fontSize: "12px", color: "red" }} className="font-weight-bold">*</Label>
                                <Input bsSize="sm" type={"select"} name="formaVenta" onChange={handleChange} value={venta.formaVenta}>
                                    <option value={0}>Seleccionar</option>
                                    <option value={"Automática"}>Automática</option>
                                    <option value={"Manual"}>Manual</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarVenta}>Guardar Venta</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default FormVentas;