import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const modeloEncabezado = {
    idCliente: 0,
    rut: "",
    nombreCliente: "",
    telefono: "",
    deudaTotal: 0,
    cantidadPlanes: 0
}

const modeloPlan = {
    idContrato: 0,
    nombrePlan: "",
    montoPlan: 0,
    formaPago: "",
    fechaIngreso: "",
    categoriaDeuda: "",
    estadoContrato:"",
}

const ConsultaExpress = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [encabezado, setEncabezado] = useState(modeloEncabezado);
    const [clientes, setClientes] = useState([])
    const [plan, setPlan] = useState(modeloPlan)
    const [busqueda, setBusqueda] = useState("")
    const [pendiente, setPendiente] = useState(false);
    const [verModalConsulta, setVerModalConsulta] = useState(false)
    const [cliente, setCliente] = useState([])
    const [planes, setPlanes] = useState(modeloPlan)
    const [historialPago, setHistorialPagos] = useState([])
    const [verModalHistorial, setVerModalHistorial] = useState(false)
    const [encabezadoHistorial, setEncabezadoHistorial] = useState([])

    const buscarClientes = () => {

        setPendiente(true)
        const api = fetch("api/contrato/ConsultaExpress/" + busqueda)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                if (data.length < 1) {
                    setPendiente(false)
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setEncabezado(data);
                setPlan(data.listaPlanes);
                setClientes(data);
                setPendiente(false)

            }).catch((error) => {
                setPendiente(false)
                setClientes([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })

    }

    const buscarHistorial = (fila) => {
        //const api = fetch("api/mantenedor/ListaPlanes/" + modeloContrato.idContrato)

        let encabezado = {
            nombrePlan: fila.nombrePlan,
            fechaIngreso: fila.fechaIngreso,
            categoriaDeuda: fila.categoriaDeuda,
            montoPlan: fila.montoPlan,
            formaPago: fila.formaPago,
            deudaTotal: fila.deudaTotal,
            fechaReactivacion: fila.fechaReactivacion,
            tipoContrato: fila.tipoContrato
        }

        const api = fetch("api/contrato/BuscarHistorialPagos/" + fila.idContrato)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setEncabezadoHistorial(encabezado);
                setHistorialPagos(data);
                mostrarModalHistorial();
            }).catch((error) => {
                setHistorialPagos([]);
            })

    }

    const mostrarModal = (data) => {
        setCliente(data)
        setPlanes(data.listaPlanes)
        setVerModalConsulta(!verModalConsulta);
    }


    const mostrarModalHistorial = () => {
        setVerModalConsulta(!verModalConsulta);
        setVerModalHistorial(!verModalHistorial);
    }

    const cerrarModalHistorial = () => {
        setVerModalConsulta(!verModalConsulta);
        setVerModalHistorial(!verModalHistorial);
    }

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const columns = [
        {
            name: 'Rut',
            selector: row => row.rut,
            sortable: true,
            grow: 2
        },
        {
            name: 'Nombre Cliente',
            selector: row => row.nombreCliente,
            sortable: true,
            grow: 2

        },
        {
            name: 'Teléfono',
            selector: row => row.telefono,
            sortable: true
        },
        {
            name: 'Cantidad Planes',
            selector: row => row.cantidadPlanes,
            sortable: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => mostrarModal(row)}>
                        <i className="fa fa-eye" aria-hidden="true"></i> Ver detalle
                    </Button>
                </>
            ),
        },
    ];

    const columnsPlanes = [
        {
            name: <div>Nombre Plan.</div > ,
            selector: row => row.nombrePlan,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Monto Plan</div >,
            selector: row => row.montoPlan,
            sortable: true,
            wrap: true,
            grow:0.5
        },
        {
            name: <div>Forma Pago</div >,
            selector: row => row.formaPago,
            sortable: true,
            wrap: true,
            grow: 0.5
        },
        {
            name: <div>Fecha Ingreso.</div >,
            selector: row => row.fechaIngreso,
            sortable: true,
            wrap: true,
            grow: 0.5
        },
        {
            name: <div>Fecha React.</div >,
            selector: row => row.fechaReactivacion,
            sortable: true,
            wrap: true,
            grow: 0.5
        },
        {
            name: <div>Categoría Deuda</div >,
            selector: row => row.categoriaDeuda,
            sortable: true,
            wrap: true,
            grow: 0.5
        },
        {
            name: <div>Tipo Contrato</div >,
            selector: row => row.tipoContrato,
            sortable: true,
            wrap: true,
            grow: 0.5
        }, 
        {
            name: <div>Estado Contrato</div >,
            selector: row => row.estadoContrato,
            sortable: true,
            wrap: true,
            grow: 0.5
        },       
        {
            name: <div>Tipo Deuda</div >,
            selector: row => row.tipoDeuda,
            sortable: true,
            wrap: true,
            grow: 0.5
        }, 
        {
            name: '',
            cell: row => (
                <>
                    <Button size="sm" color="info" outline onClick={() => buscarHistorial(row)}>
                        {/*<i className="fa fa-eye" aria-hidden="true"></i>Ver Historial*/}
                        <i className = "fas fa-money-check-alt"></i>
                    </Button>
                </>
            ),
            wrap: true
        },
    ];

    const columnsHistorial = [
        {
            name: <div>Fecha</div>,
            selector: row => row.fecha,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Plan</div>,
            selector: row => row.nombrePlan,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Monto Plan</div>,
            selector: row => row.montoPlan,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Monto Pago</div>,
            selector: row => row.montoPago,
            sortable: true,
            wrap: true
        },
        //{
        //    name: <div>Saldo</div>,
        //    selector: row => row.saldoPlan,
        //    sortable: true,
        //    wrap: true
        //},
    ];

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Consulta Express
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Cliente:</Label>
                                        <Input bsSize="sm" value={busqueda} onChange={(e) => setBusqueda(e.target.value)} />
                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscarClientes}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col sm={12}>
                                <div className="table table-responsive">
                                        <DataTable
                                        columns={columns}
                                        data={clientes}
                                        progressPending={pendiente}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        customStyles={customStyles}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                        paginationPerPage={10}
                                        noDataComponent="No se encontraron resultados"
                                    />
                                    </div>   
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal size="xl" isOpen={verModalConsulta} className="modal-dialog-scrollable">
                <ModalHeader>
                    Detalle Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={cliente.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Cliente :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={cliente.nombreCliente} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Cantidad Planes :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={cliente.cantidadPlanes} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                        <div className="table table-responsive">
                            <DataTable
                                columns={columnsPlanes}
                                data={planes}
                                //progressPending={pendiente}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                paginationRowsPerPageOptions={[5, 15, 25, 50]}
                                paginationPerPage={5}
                                noDataComponent="No se encontraron resultados"
                            />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => setVerModalConsulta(!verModalConsulta)}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="xl" isOpen={verModalHistorial} className="modal-dialog-scrollable">
                <ModalHeader>
                    Historial Pagos
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Rut :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={cliente.rut} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Cliente :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={cliente.nombreCliente} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Ingreso :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.fechaIngreso} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Categoría Deuda :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.categoriaDeuda} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Nombre Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.nombrePlan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Monto Plan :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.montoPlan} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Forma Pago :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.formaPago} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Deuda Total :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.deudaTotal} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Fecha Reactivación :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.fechaReactivacion} />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label style={{ fontSize: "12px" }} className="font-weight-bold">Tipo Contrato :</Label>
                                <Input style={{ fontSize: "12px" }} bsSize="sm" disabled value={encabezadoHistorial.tipoContrato} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col sm={12}>
                            <div className="table table-responsive">
                                <DataTable
                                    columns={columnsHistorial}
                                    data={historialPago}
                                    //progressPending={pendiente}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    customStyles={customStyles}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                    paginationPerPage={10}
                                    noDataComponent="No se encontraron resultados"
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={cerrarModalHistorial}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )

}

export default ConsultaExpress;