import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter } from "reactstrap"
import Swal from 'sweetalert2'

import { UserContext } from '../context/UserProvider';
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom"

const modeloVendedor = {
    idVendedor: 0,
    nombreVendedor: "",
    activo: true
}

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Vendedor = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    let administrador = false;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor" || dataUser.idRolNavigation.descripcion == "Digitador") ? (administrador = true) : (administrador = false);

    const [vendedor, setVendedor] = useState(modeloVendedor);
    const [pendiente, setPendiente] = useState(true);
    const [vendedores, setVendedores] = useState([]);
    const [verModal, setVerModal] = useState(false);

    const handleChange = (e) => {
        let value = e.target.nodeName === "SELECT" ? (e.target.value == "true" ? true : false) : e.target.value;

        setVendedor({
            ...vendedor,
            [e.target.name]: value
        })
    }

    const obtenerVendedores = async () => {
        let response = await fetch("api/mantenedor/ListaVendedores");

        if (response.ok) {
            let data = await response.json()
            setVendedores(data)
            setPendiente(false)
        }
    }

    useEffect(() => {
        obtenerVendedores();
    }, [])


    const columns = [
        {
            name: 'Id Vendedor',
            selector: row => row.idVendedor,
            sortable: true,
        },
        {
            name: 'Nombre vendedor',
            selector: row => row.nombreVendedor,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row.activo,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.activo ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.activo ? "Activo" : "No Activo"}</span>
                )
            }
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    const abrirEditarModal = (data) => {
        setVendedor(data);
        setVerModal(!verModal);
    }

    const cerrarModal = () => {
        setVendedor(modeloVendedor)
        setVerModal(!verModal);
    }

    const guardarCambios = async () => {

        let response;
        if (vendedor.idVendedor == 0) {
            response = await fetch("api/mantenedor/GuardarVendedor", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(vendedor)
            })

        } else {
            response = await fetch("api/mantenedor/EditarVendedor", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(vendedor)
            })
        }

        if (response.ok) {
            if (vendedor.idVendedor == 0) {
                Swal.fire(
                    'Creación!',
                    'Vendedor creado con éxito.',
                    'success'
                )
            } else {
                Swal.fire(
                    'Modificación!',
                    'El vendedor se ha modificado éxito.',
                    'success'
                )
            }

            await obtenerVendedores();
            setVendedor(modeloVendedor)
            setVerModal(!verModal);

        } else {
            alert("error al guardar")
        }

    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Vendedores
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Vendedor</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={vendedores}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>

            <Modal isOpen={verModal}>
                <ModalHeader>
                    Vendedor
                </ModalHeader>
                <ModalBody>
                    {/*<FormGroup>*/}
                    {/*    <Label>Id Vendedor</Label>*/}
                    {/*    <Input bsSize="sm" name="nombrePlan" onChange={handleChange} value={vendedor.idVendedor} />*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                        <Label>Nombre Vendedor</Label>
                        <Input bsSize="sm" name="nombreVendedor" onChange={handleChange} value={vendedor.nombreVendedor} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Estado</Label>
                        <Input bsSize="sm" type={"select"} name="activo" onChange={handleChange} value={vendedor.activo} >
                            <option value={true}>Activo</option>
                            <option value={false}>No Activo</option>
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>



    )
}

export default Vendedor;