import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/UserProvider';
import { number } from "prop-types";
import * as XLSX from "xlsx"
import { Navigate } from "react-router-dom"


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}


const ReporteCierreMes = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [clientes, setClientes] = useState([])
    const [mes, setMes] = useState(1)
    const [anio, setAnio] = useState(2023)
    const [tipo, setTipo] = useState(1)

    let administrador;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);

    const cambioMes = (e) => {

        let value = e.target.value;
        setMes(value)
    }

    const cambioAnio = (e) => {

        let value = e.target.value;
        setAnio(value)
    }

    const cambioTipo = (e) => {

        let value = e.target.value;
        setTipo(value)
    }

    const buscar = () => {

        if (tipo == 1) {
            const api = fetch(`api/mantenedor/ReporteCierreMesCobranza?mes=${mes}&anio=${anio}&tipo=${tipo}`)
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    var data = dataJson;
                    if (data.length < 1) {
                        Swal.fire(
                            'Opps!',
                            'No se encontraron resultados',
                            'warning'
                        )
                    }
                    else {
                        exportarExcel(data);
                    }
                    

                }).catch((error) => {
                    setClientes([]);
                    Swal.fire(
                        'Opps!',
                        'No se pudo encontrar información',
                        'error'
                    )
                })
        }
        else {
            const api = fetch(`api/mantenedor/ReporteCierreMesRecurrente?mes=${mes}&anio=${anio}&tipo=${tipo}`)
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    var data = dataJson;
                    if (data.length < 1) {
                        Swal.fire(
                            'Opps!',
                            'No se encontraron resultados',
                            'warning'
                        )
                    }
                    else {
                        exportarExcel(data);
                    }

                }).catch((error) => {
                    setClientes([]);
                    Swal.fire(
                        'Opps!',
                        'No se pudo encontrar información',
                        'error'
                    )
                })
        }

        
    }


    const exportarExcel = (data) => {

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(data);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");

        if (tipo == 1) {
            XLSX.writeFile(wb, "Reporte cierres mes cobranza.xlsx")
        }
        else {
            XLSX.writeFile(wb, "Reporte cierres mes recurrente.xlsx")
        }
        
    }

    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Reporte Cierre Mes
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Mes:</Label>
                                        <Input bsSize="sm" type={"select"} name="mes" onChange={cambioMes}>
                                            <option value={1}>Enero</option>
                                            <option value={2}>Febrero</option>
                                            <option value={3}>Marzo</option>
                                            <option value={4}>Abril</option>
                                            <option value={5}>Mayo</option>
                                            <option value={6}>Junio</option>
                                            <option value={7}>Julio</option>
                                            <option value={8}>Agosto</option>
                                            <option value={9}>Septiembre</option>
                                            <option value={10}>Octubre</option>
                                            <option value={11}>Noviembre</option>
                                            <option value={12}>Diciembre</option>

                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Año:</Label>
                                        <Input bsSize="sm" type={"select"} name="mes" onChange={cambioAnio}>
                                            <option value={2023}>2023</option>
                                            <option value={2024}>2024</option>
                                            <option value={2025}>2025</option>
                                            <option value={2026}>2026</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Planilla:</Label>
                                        <Input bsSize="sm" type={"select"} name="mes" onChange={cambioTipo}>
                                            <option value={1}>Cobranza</option>
                                            <option value={2}>Recurrente</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={buscar}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Descargar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </>
    )
}

export default ReporteCierreMes;