import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Swal from 'sweetalert2'
import * as XLSX from "xlsx"
import { UserContext } from '../context/UserProvider';
import React, { useContext } from 'react';
import { Navigate } from "react-router-dom"
import { useRef } from 'react';


const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const VentasNuevas = () => {

    const { user } = useContext(UserContext);

    const [dataUser, setDataUser] = useState(modelo)

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)

    }, [])

    const [excelVentas, setVentasExcel] = useState("")
    const [respuesta, setRespuesta] = useState([]);
    const [pendienteBusqueda, setPendienteBusqueda] = useState(false);

    const inputRef = useRef(null);

    let administrador = false;
    (dataUser.idRolNavigation.descripcion == "Administrador" || dataUser.idRolNavigation.descripcion == "Editor") ? (administrador = true) : (administrador = false);


    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                console.log(ws)

                const data = XLSX.utils.sheet_to_json(ws, { raw: false });

                resolve(data);

                setVentasExcel(data);

                // console.log(this.state);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            // console.log(d)
        });
    }


    const guardarCambios = () => {


        let response;
        let modeloVentas = {
            idUsuario: JSON.parse(user).idUsuario,
            jsonVentas: JSON.stringify(excelVentas)
        };
        
        //response = fetch("api/mantenedor/VentasNuevas",
        //        {
        //        method: 'POST',
        //        headers: {
        //            'Content-Type': 'application/json;charset=utf-8'
        //        },
        //            body: JSON.stringify(modeloVentas)
        //    })
        //    .then((dataJson) => {
        //        var data = dataJson;
        //        console.log('La respuesta es ' + JSON.stringify(data.listaRespuesta))
        //        if (data.length < 1) {
        //            Swal.fire(
        //                'Opps!',
        //                'No se encontraron resultados',
        //                'warning'
        //            )
        //        }
        //        else {
        //            Swal.fire(
        //            'Actualización exitosa!',
        //            'success'
        //            )
        //        }

        //    }).catch((error) => {
               
        //        Swal.fire(
        //            'Opps!',
        //            'No se pudo encontrar información',
        //            'error'
        //        )
        //    })
      

        const api = fetch("api/mantenedor/VentasNuevas", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(modeloVentas)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                
                var data = dataJson;
                setRespuesta(data.listaRespuesta)
                inputRef.current.value = null;
                Swal.fire(
                    'Ventas Creadas!',
                    'success'
                )

            }).catch((error) => {
                inputRef.current.value = null;
                Swal.fire(
                    'Opps!',
                    'No se pudo crear la venta',
                    'error'
                )
                //console.log("No se pudo crear la venta", JSON.stringify(modeloVentas))
            })

    }

    const procesarVentas = () => {

        let modeloVentas = {
            idUsuario: JSON.parse(user).idUsuario,
            jsonVentas: excelVentas
        }


        //console.log(excelVentas)
        const api = fetch("api/contrato/VentasNuevas", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(modeloVentas)
        })
            .then((response) => {
                return response.ok;
            })
            .then((dataJson) => {


                Swal.fire(
                    'Actualización exitosa!',
                    'success'
                )

            }).catch((error) => {
                Swal.fire(
                    'Opps!',
                    'No se pudo realizar la modificación',
                    'error'
                )
            })
    }

    const columns = [
        {
            name: <div>Id Contrato</div>,
            selector: row => row.idContrato,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Rut</div>,
            selector: row => row.rut,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Nombre Cliente</div>,
            selector: row => row.nombres + ' ' + row.apellidoP,
            sortable: true,
            wrap: true

        },
        {
            name: <div>Teléfono</div>,
            selector: row => row.telefono,
            sortable: true,
            wrap: true
        },
        {
            name: <div>Plan</div>,
            selector: row => row.nombrePlan,
            sortable: true,
            wrap: true
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '11px',
                width: "150px",
                //fontWeight: 800,
            },

        },
        cells: {
            style: {
                fontSize: '11px'
            },

        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
  
    if (!administrador && dataUser.idRolNavigation.descripcion != "") {
        //return <Redirect to="/clientes" />
        return <Navigate to="/" />
        //this.props.history.push('/clientes');
    }

    return (
        <>
            
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Ingreso Ventas
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <input id="inputExcel" ref={inputRef}
                                        type="file" 
                                        onChange={(e) => {
                                            const archivo = e.target.files[0];
                                            readExcel(archivo);
                                        }}
                                    />
                                </Col>
                                <Col sm={3}>
                                    <Button color="primary" size="sm" block onClick={guardarCambios}>
                                         Procesar Excel
                                    </Button>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        //progressPending={pendiente}
                                        columns={columns}
                                        data={respuesta}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                        paginationPerPage={10}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default VentasNuevas;